<template>
    <draggable
        v-if="!updatingNestedFilter"
        :list="filterElems"
        class="dragArea list-group movable"
        ghost-class="ghost"
        :group="{ name: 'g1' }"
        @start="dragging = true"
        @end="dragging = false"
    >
        <div
            :class="(depth % 2 == 0) ? 'nested-light list-group-item' : 'nested-dark list-group-item'"
            v-for="(element, index) in filterElems"
            :key="element.id"
        >
            <div v-if="element.isGroup">
                <div class="d-flex flex-column pb-2">
                    <div :class="(!dragging) ? `d-flex flex-row align-items-center pb-2` : 'd-none'" slot="header">
                        <div style="font-size: 1.5rem;" class="pr-3">
                            <b-icon-grip-horizontal ></b-icon-grip-horizontal>
                        </div>
                        <div>
                            <b-button class="px-1 mr-1 py-1" variant="danger" @click="deleteGroup(index)">
                                <b-icon-trash-fill></b-icon-trash-fill>
                            </b-button>
                        </div>
                        <div>
                            <b-button class="px-1 mr-1 py-1" variant="success" @click="openFilterModalForAdd(element)">
                                Add Field To Group
                            </b-button>
                        </div>
                        <div>
                            <b-form-checkbox
                                :id="`${element.id}-negate`"
                                v-model="element.negate"
                                :name="`cb-negate-group-${element.id}`"
                                :value="true"
                                :unchecked-value="false"
                            >
                                Negate Group (Apply NOT to entire group)
                            </b-form-checkbox>
                            <b>{{ element.name }}</b>
                        </div>
                    </div>
                    <div class="pl-5">
                        <nested-filter 
                            :class="`group-nest-area ` + ((dragging) ? 'group-nest-area-hover' : '')" 
                            :filterElems="element.children" :depth="depth + 1" 
                            :availableFilterFields="availableFilterFields" 
                            :allFields="allFields" 
                            :filterOperatorsPerType="filterOperatorsPerType" 
                            :modifiedCallback="modifiedCallback"
                        ></nested-filter>   
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="d-flex flex-row align-items-center">
                    <div class="d-flex flex-row align-items-center pb-2">
                        <div style="font-size: 1.5rem;" :class="(!dragging) ? 'pr-3' : 'd-none' ">
                            <b-icon-grip-horizontal ></b-icon-grip-horizontal>
                        </div>
                        <div :class="(!dragging) ? '' : 'd-none'">
                            <b-button class="px-1 mr-1 py-1" variant="danger" @click="deleteGroup(index)">
                                <b-icon-trash-fill></b-icon-trash-fill>
                            </b-button>
                        </div>
                        <div :class="(!dragging) ? '' : 'd-none'">
                            <b-button class="px-1 mr-1 py-1" variant="info" @click="openFilterModalForEdit(element)">
                                <b-icon-pencil-fill></b-icon-pencil-fill>
                            </b-button>
                        </div>
                    </div>
                    <div class="ml-2">
                        <span v-if="element.operator == 'OLDERTHANDAYSAGO'"><b>{{ element.fieldName }}</b> <i>more than <b>{{element.value}}</b> days ago</i></span>
                        <span v-else-if="element.operator == 'YOUNGERTHANDAYSAGO'"><b>{{ element.fieldName }}</b> <i>within the last <b>{{element.value}}</b> days</i></span>
                        <span v-else><b>{{ element.fieldName }}</b> <i>{{element.operatorName}}</i> <b>{{element.value}}</b></span>
                    </div>
                </div>
            </div>
            <b-form-select 
                v-show="!dragging" 
                v-if="index != filterElems.length -1 " 
                class="filter-combinator-select ml-2" 
                :id="element.field + '-' + index + '-combinator'" 
                v-model="element.combination" 
                :options="combinationOptions"
            ></b-form-select>
        </div>
        <b-modal 
            ref="filter-modal" 
            :title="filterModal.title"
            hide-footer
        >
            <b-form-group
                id="filter-modal-selected-field-group"
                description="Field must be on list or from one of the groups which has a field on the list."
                label="Select A Field"
                label-for="filter-modal-selected-field"
            >
                <b-form-select id="filter-modal-selected-field" v-model="filterModal.selectedField" :options="availableFilterFields" @change="updateFilterAvailableCompareOptionsAndFieldType()"></b-form-select>
            </b-form-group>
            <b-form-group v-if="filterModal.selectedField != null"
                id="filter-modal-operator-group"
                label="Operator"
                label-for="filter-modal-operator"
            >
                <b-form-select id="filter-modal-operator" v-model="filterModal.compareOperator" :options="filterModal.operatorOptions"></b-form-select>
            </b-form-group>
            <b-form-group v-if="filterModal.compareOperator != null && (filterModal.selectedFieldType == 'text' || filterModal.selectedFieldType == 'text[]') && filterModal.compareOperator != 'NULL' && filterModal.compareOperator != 'NOTNULL'  && filterModal.compareOperator != 'ARRAYLENGTHATLEASTONE'"
                id="filter-modal-input-group"
                label="Input"
                label-for="filter-modal-input"
            >
                <b-form-input :type="'text'" id="filter-modal-input" v-model="filterModal.compareTo"></b-form-input>
            </b-form-group>
            <b-form-group v-if="filterModal.compareOperator != null && filterModal.selectedFieldType == 'numeric' && filterModal.compareOperator != 'NULL' && filterModal.compareOperator != 'NOTNULL'"
                id="filter-modal-input-group"
                label="Input"
                label-for="filter-modal-input"
            >
                <b-form-input :type="'number'" id="filter-modal-input" v-model="filterModal.compareTo"></b-form-input>
            </b-form-group>
            <b-form-group v-if="filterModal.compareOperator != null && filterModal.selectedFieldType == 'integer' && filterModal.compareOperator != 'NULL' && filterModal.compareOperator != 'NOTNULL'"
                id="filter-modal-input-group"
                label="Input"
                label-for="filter-modal-input"
            >
                <b-form-input :type="'number'" id="filter-modal-input" v-model="filterModal.compareTo"></b-form-input>
            </b-form-group>
            <b-form-group v-if="filterModal.compareOperator != null && filterModal.selectedFieldType == 'timestamp with time zone' && (filterModal.compareOperator == 'OLDERTHANDAYSAGO' || filterModal.compareOperator == 'YOUNGERTHANDAYSAGO')"
                id="filter-modal-input-group"
                label="Input"
                label-for="filter-modal-input"
            >
                <b-input-group :prepend="(filterModal.compareOperator == 'OLDERTHANDAYSAGO') ? 'more than' : 'within the last'" :append="(filterModal.compareOperator == 'OLDERTHANDAYSAGO') ? 'days ago' : 'days'">
                    <b-form-input :type="'number'" id="filter-modal-input" min="1" max="10000" v-model="filterModal.compareTo"></b-form-input>
                </b-input-group>
                
            </b-form-group>
            <b-form-group v-if="filterModal.compareOperator != null && filterModal.selectedFieldType == 'timestamp with time zone' && filterModal.compareOperator != 'OLDERTHANDAYSAGO' && filterModal.compareOperator != 'YOUNGERTHANDAYSAGO' && filterModal.compareOperator != 'LASTMONTH' && filterModal.compareOperator != 'LAST90DAYS' && filterModal.compareOperator != 'LASTYEAR' && filterModal.compareOperator != 'THISYEAR' && filterModal.compareOperator != 'NULL' && filterModal.compareOperator != 'NOTNULL'"
                id="filter-modal-input-group"
                label="Input"
                label-for="filter-modal-input"
            >
                <b-form-input :type="'date'" id="filter-modal-input" v-model="filterModal.compareTo"></b-form-input>
            </b-form-group>
            <b-form-group v-if="filterModal.compareOperator != null && filterModal.selectedFieldType == 'boolean' && filterModal.compareOperator != 'NULL' && filterModal.compareOperator != 'NOTNULL'"
                id="filter-modal-input-group"
                label="Input"
                label-for="filter-modal-input"
            >
                <b-form-select id="filter-modal-input" :options="booleanCompareToOptions" v-model="filterModal.compareTo"></b-form-select>
            </b-form-group>
            <hr>
            
            <b-button 
                :disabled="filterModal.compareTo == null && filterModal.compareOperator != 'LASTMONTH' && filterModal.compareOperator != 'LAST90DAYS' && filterModal.compareOperator != 'LASTYEAR' && filterModal.compareOperator != 'THISYEAR' && filterModal.compareOperator != 'NULL' && filterModal.compareOperator != 'NOTNULL' && filterModal.compareOperator != 'ARRAYLENGTHATLEASTONE'" 
                class="float-right ml-2" 
                variant="success" 
                @click="doneFilterElement()"
            >
                {{filterModal.okTitle}}
            </b-button>
            <b-button class="float-right" variant="warning" @click="closeFilterModal">Cancel</b-button>
        </b-modal>
    </draggable>
</template>

<script>
const uuidv4 = require('uuid/v4');
const _ = require('underscore');
import draggable from 'vuedraggable'

export default {
    name: 'nested-filter',
    components:{
        draggable
    },
    props:{
        depth: {
            type: Number,
            required: true,
            default: 0
        },
        filterElems: {
            type: Array,
            required: false,
            default: null
        },
        availableFilterFields:{
            type: Array,
            required: true,
            default: null
        },
        allFields:{
            type: Array,
            required: true,
            default: null
        },
        filterOperatorsPerType:{
            type: Object,
            required: true,
            default: null
        },
        modifiedCallback:{
            type: Function,
            required: true
        }
    },
    data(){
        return{
            updatingNestedFilter: false,
            dragging : false,
            combinationOptions: [
                { text: 'AND', value: 'AND'},
                { text: 'OR', value: 'OR', disabled: !this.$store.getters.getListBuilderSettings.allow_or}
            ],
            filterModal: {
                parentIndex: 0,
                editingIndex: 0,
                title: null,
                okTitle: null,
                selectedField: null,
                compareOperator: null,
                compareTo: null,
                operatorOptions: [],
                selectedFieldType: null
            },
            booleanCompareToOptions: [
                { text: 'True', value: 'TRUE' },
                { text: 'False', value: 'FALSE' },
                { text: 'Null', value: 'NULL' }
            ],
        }
    },
    methods: {
        updateFilterAvailableCompareOptionsAndFieldType(){
            this.filterModal.operatorOptions = [];
            console.log(this.filterModal.selectedField)
            // Find the field by it's id, which is what the filter modal's selection knows
            var matches = this.allFields.filter((avl)=>{ return avl.id == this.filterModal.selectedField });
            var selectedMatch = {};
            if(matches.length > 0){
                // Take the first match
                selectedMatch = matches[0];
            }
            this.filterModal.selectedFieldType = selectedMatch.type;
            this.filterModal.operatorOptions = this.filterOperatorsPerType[selectedMatch.type];
        },
        deleteGroup(index){
            this.filterElems.splice(index, 1);
            this.modifiedCallback();
        },
        deleteFilter(){

        },
        closeFilterModal(){
            this.filterModal = {
                editingFilter: null,
                title: null,
                okTitle: 'OK',
                selectedField: null,
                compareOperator: null,
                compareTo: null,
                operatorOptions: [],
                selectedFieldType: null
            };
            this.$refs['filter-modal'].hide();
        },
        openFilterModalForEdit(filter){
             this.filterModal = {
                editingFilter: filter,
                title: "Edit Filter",
                okTitle: 'Done',
                selectedField: _.clone(filter.field),
                compareOperator: _.clone(filter.operator),
                compareTo: _.clone(filter.value),
                operatorOptions: [],
                selectedFieldType: null
            };
            this.updateFilterAvailableCompareOptionsAndFieldType();
            this.$refs['filter-modal'].show()
        },
        openFilterModalForAdd(element){
            this.filterModal = {
                editingFilter: null,
                parent: element,
                title: "Add Filter",
                okTitle: 'Add',
                selectedField: null,
                compareOperator: null,
                compareTo: null,
                operatorOptions: [],
                selectedFieldType: null
            };
            this.$refs['filter-modal'].show()
        },
        doneFilterElement(){
            if(this.filterModal.editingFilter == null){
                this.addFilterElement();
            }else{
                this.editFilterElement();
            }
        },
        addFilterElement(){
            // TODO: Validate
            var field = this.allFields.filter((avl)=>{ return avl.id == this.filterModal.selectedField })[0];
            var operator = this.filterOperatorsPerType[field.type].filter((fop)=>{ return fop.value == this.filterModal.compareOperator; })[0];
            var tmp = { 
                id: uuidv4(),
                fieldName: field.display_name,
                parent: field.ui_group,
                operatorName: operator.text,
                field: this.filterModal.selectedField,
                operator: this.filterModal.compareOperator,
                value: this.filterModal.compareTo,
                combination: 'AND',
                isGroup: false,
                children: []
            }
            this.filterModal.parent.children.push(tmp);
            this.closeFilterModal();
            this.modifiedCallback();
        },
        editFilterElement(){
            var operator = this.filterModal.operatorOptions.filter((fop)=>{ return fop.value == this.filterModal.compareOperator; })[0];
            this.filterModal.editingFilter.id = uuidv4();
            this.filterModal.editingFilter.operatorName = operator.text;
            this.filterModal.editingFilter.field = this.filterModal.selectedField;
            this.filterModal.editingFilter.operator = this.filterModal.compareOperator;
            this.filterModal.editingFilter.value = this.filterModal.compareTo;
            this.closeFilterModal();
            this.modifiedCallback();
        },
    },
    computed: {
        operatorsForSelectedFieldType: function (){
            if(this.filterModal.selectedField != null){
                // console.log(this.filterModal.selectedField)
                // console.log(this.allFields.filter((avl)=>{ return avl.id == this.filterModal.selectedField }))
                return this.filterOperatorsPerType[this.allFields.filter((avl)=>{ return avl.id == this.filterModal.selectedField })[0].type];
            }else{
                return [];
            }
        }
    },
    mounted(){
    },
    created(){
    },
    beforeDestroy(){
    },
    beforeMount (){
        //console.log(this.value)
    },
}
</script>
<style scoped>
div.nested-filter-negation{
    display: flex;
    flex-direction: column;
}
div.drag-indicator{
    position: relative;
    top: 0;
    left: 0;
}
.giant-padding-bottom{
    padding-bottom: 5rem;
}
.hoverable{
    cursor:pointer;
}
.clickable{
    cursor: pointer;
}
.movable{
    cursor: move;
}
.filter-combinator-select{
    width: 10vw;
}
.group-nest-area{
    margin: 0 0 0 1rem;
    /* padding: 1rem; */
    border: solid black 1px;
}
.group-nest-area-hover{
    background-color: aqua;
    padding-bottom: 1rem;
}
.dragArea {
  min-height: 50px;
  outline: 1px dashed;
}
.nested-dark{
    background-color: rgb(129, 129, 129);
}
.nested-light{
    background-color: rgb(180, 180, 180);
}
</style>

