<template>
    <div id="fit-window" ref="fitWindow">
        <b-nav id="lb-sidebar" :class="(sidebarOpen) ? 'lb-sb-nav-open' : 'lb-sb-nav-closed'" vertical>
            <div class="my-1">
                <b-button @click="fetch()" v-if="!waiting" :disabled="(fields.length > 0)? false : true">Get List</b-button>
                <b-button v-else :disabled="(fields.length > 0)? false : true">
                    <b-spinner variant="vai-main-orange" type="grow" label="Spinning"></b-spinner>
                </b-button>
                <b-dropdown id="table-actions-dropdown" text="Actions & Utils" class="ml-2">
                    <b-dropdown-item :disabled="(items.length > 0)? false : true" v-if="$store.getters.checkUIPC({path: ['listBuilder', 'actions', 'canDownloadCSV'] })" @click="downloadCSV()">Download CSV</b-dropdown-item>
                    <b-dropdown-divider v-if="$store.getters.checkUIPC({path: ['listBuilder', 'actions', 'canDownloadCSV'] })"></b-dropdown-divider>
                    <b-dropdown-item :disabled="(items.length > 0 && fields.length > 1)? false : true" v-if="$store.getters.checkUIPC({path: ['listBuilder', 'actions', 'canPivotTable'] })" @click="openPivotModal()">Create Pivot Table</b-dropdown-item>
                    <b-dropdown-divider v-if="$store.getters.checkUIPC({path: ['listBuilder', 'actions', 'canPivotTable'] })"></b-dropdown-divider>
                    <b-dropdown-item :disabled="(items.length > 0 && fields.length > 1)? false : true" v-if="$store.getters.checkUIPC({path: ['eventList', 'actions', 'downloadPDF'] })" @click="downloadAllOfficialReports()">Download All Official Test Reports In List</b-dropdown-item>
                    <b-dropdown-divider v-if="$store.getters.checkUIPC({path: ['eventList', 'actions', 'downloadPDF'] })"></b-dropdown-divider>
                    <b-dropdown-item :disabled="(items.length > 0 && fields.length > 1)? false : true" v-if="$store.getters.checkUIPC({path: ['fulcrumBulkIssue', 'access'] })" @click="issueToFulcrum()">Issue Eligible Records to Fulcrum</b-dropdown-item>
                    <b-dropdown-item :disabled="(items.length > 0 && fields.length > 1)? false : true" v-if="$store.getters.checkUIPC({path: ['emailBuilder', 'access'] })" @click="jumpToEmailBuilder()">Build An Email Batch With This List</b-dropdown-item>
                    <b-dropdown-item :disabled="(items.length > 0 && fields.length > 1)? false : true" v-if="$store.getters.checkUIPC({path: ['letterBuilder', 'access'] })" @click="jumpToLetterBuilder()">Build A Letter Batch With This List</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-text> Set Image Thumbnail Size:</b-dropdown-text>
                    <b-dropdown-item-button v-for="opt in photoThumbnailSizeOptions" :key="opt.value" :active="(opt.value == photoThumbnailSize) ? true : false" @click="setThumbSize(opt.value)">{{opt.text}}</b-dropdown-item-button>
                </b-dropdown>
            </div>
            <b-tabs content-class="mt-3" id="lb-sb-tabs">
                    <b-tab title="Fields" class="scrollable-veritically">
                        <div v-for="(parent, index) in tree" :key="parent.name">
                            <div>
                                <span class="align-middle clickable" v-if="parent.expanded" @click="parentToggle(index)">
                                    <i class="material-icons drop-down">expand_more</i>
                                </span>
                                <span class="align-middle clickable" v-else @click="parentToggle(index)">
                                    <i class="material-icons drop-down">chevron_right</i>
                                </span>
                                <span :class="`align-middle clickable ${(parentAllowed(parent)) ? '': 'text-secondary'}`" @click="parentToggle(index)">
                                    {{parent.name}}
                                </span>
                                <b-icon-question-circle v-show="parent.showInfo" :id="`field-group-${index}-help`"></b-icon-question-circle>
                                <b-popover :target="`field-group-${index}-help`" triggers="hover" placement="bottom" boundary="window">
                                    <template #title>Why Can't I Select Any Of These Fields?</template>
                                    {{parent.infoValue}}
                                </b-popover>
                            </div>
                            
                            <div v-if="parent.expanded">
                                <div class="expanded-child ml-4 mb-1" v-for="(child, index) in parent.children" :key="child.name">
                                    <b-form-checkbox
                                        class="ml-2"
                                        :id="'checkbox-' + child.name + '-' + index"
                                        v-model="child.selected"
                                        :name="'checkbox-' + child.name + '-' + index"
                                        :disabled="parent.showInfo"
                                        @change="addOrRemoveFromTableFields(child)"
                                    >
                                        {{child.name}}
                                    </b-form-checkbox>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Save" v-if="$store.getters.checkUIPC({path: ['listBuilder', 'actions', 'canSave'] })">
                        <div class="mx-2" v-if="loadedFromPackage != null">
                            <div v-if="loadedFromPackage.justSaved == true || loadedFromPackage.user_id == this.$store.getters.userID || (loadedFromPackage.account_id == this.$store.getters.accountID && loadedFromPackage.allow_account_editing)">
                                <b-form-group
                                    id="save-name-title"
                                    description="This is the name displayed in the list. We recommend a short and sweet name, but we're not the boss of you..."
                                    label="List Display Name"
                                    label-for="save-name"
                                    
                                >
                                    <b-form-input id="save-name" v-model="savePackage.name" required trim></b-form-input>
                                </b-form-group>
                                <b-form-group
                                    id="save-desc-title"
                                    description="(Optional) - Maybe helpful to keep notes in here about exactly what this list is for."
                                    label="List Description"
                                    label-for="save-desc"
                                >
                                    <b-form-textarea id="save-desc" v-model="savePackage.description" trim></b-form-textarea>
                                </b-form-group>

                                <b-form-group
                                    v-if="savePackage.permissions == 'user'"
                                    id="save-hidden-title"
                                    description="Show this list in the list of available lists (only applies for 'Only For Me' lists)"
                                    label="Show/Hide From List"
                                    label-for="save-hidden"
                                >
                                    <b-form-checkbox v-model="savePackage.disabled" name="save-hidden" switch>
                                        Hide From List
                                    </b-form-checkbox>
                                </b-form-group>

                                <b-form-group
                                    id="save-perms-title"
                                    description="Sharing Company-Wide will allow other users in your organization to use this list as well. Giving (Read-Only) access means that they can see this list but cannot save any changes that they make, (Editable) means that they can save edits they make."
                                    label="List Use:"
                                    label-for="save-perms"
                                >
                                    <b-form-radio-group
                                        v-model="savePackage.permissions"
                                    >
                                        <b-form-radio value="user">Only For Me</b-form-radio>
                                        <b-form-radio value="account">Company-Wide (Read-Only)</b-form-radio>
                                        <b-form-radio value="accountEdit">Company-Wide (Editable)</b-form-radio>
                                    </b-form-radio-group>
                                </b-form-group>
                                <b-button
                                    :disabled="(savePackage.permissions != null && savePackage.name != null && savePackage.name != '') ? false : true"
                                    :variant="(savePackage.name != null && savePackage.name != '') ? 'success' : 'danger' "
                                    @click="createOrUpdateSavedList()"
                                    block
                                >
                                    Save List
                                </b-button>
                                <b-button
                                    :disabled="(savePackage.permissions != null && savePackage.name != null && savePackage.name != '' && savePackage.id != null) ? false : true"
                                    :variant="(savePackage.name != null && savePackage.name != '') ? 'success' : 'danger' "
                                    @click="saveListCopy()"
                                    block
                                >
                                    Save as a Copy
                                </b-button>
                            </div>
                            <div v-else>
                                <span>You cannot save any changes to this form... Save a copy then reopen it to continue tracking your changes</span>
                                <b-form-group
                                    id="save-name-title"
                                    description="This is the name displayed in the list. We recommend a short and sweet name, but we're not the boss of you..."
                                    label="List Display Name"
                                    label-for="save-name"
                                    
                                >
                                    <b-form-input id="save-name" v-model="savePackage.name" required trim></b-form-input>
                                </b-form-group>
                                <b-button
                                    :disabled="(savePackage.name != null && savePackage.name != '' && savePackage.id == null) ? false : true"
                                    :variant="(savePackage.name != null && savePackage.name != '') ? 'success' : 'danger' "
                                    @click="saveListCopy()"
                                    block
                                >
                                    <span v-if="savePackage.id == null">You've Already Saved A Copy</span>
                                    <span v-else>Save as a Copy</span>
                                </b-button>
                            </div>
                        </div>
                        <div class="mx-2" v-else>
                            <div>
                                <b-form-group
                                    id="save-name-title"
                                    description="This is the name displayed in the list. We recommend a short and sweet name, but we're not the boss of you..."
                                    label="List Display Name"
                                    label-for="save-name"
                                    
                                >
                                    <b-form-input id="save-name" v-model="savePackage.name" required trim></b-form-input>
                                </b-form-group>
                                <b-form-group
                                    id="save-desc-title"
                                    description="(Optional) - Maybe helpful to keep notes in here about exactly what this list is for."
                                    label="List Description"
                                    label-for="save-desc"
                                >
                                    <b-form-textarea id="save-desc" v-model="savePackage.description" trim></b-form-textarea>
                                </b-form-group>

                                <b-form-group
                                    v-if="savePackage.permissions == 'user'"
                                    id="save-hidden-title"
                                    description="Show this list in the list of available lists (only applies for 'Only For Me' lists)"
                                    label="Show/Hide From List"
                                    label-for="save-hidden"
                                >
                                    <b-form-checkbox v-model="savePackage.disabled" name="save-hidden" switch>
                                        Hide From List
                                    </b-form-checkbox>
                                </b-form-group>

                                <b-form-group
                                    id="save-perms-title"
                                    description="Sharing Company-Wide will allow other users in your organization to use this list as well. Giving (Read-Only) access means that they can see this list but cannot save any changes that they make, (Editable) means that they can save edits they make."
                                    label="List Use:"
                                    label-for="save-perms"
                                >
                                    <b-form-radio-group
                                        v-model="savePackage.permissions"
                                    >
                                        <b-form-radio value="user">Only For Me</b-form-radio>
                                        <b-form-radio value="account">Company-Wide (Read-Only)</b-form-radio>
                                        <b-form-radio value="accountEdit">Company-Wide (Editable)</b-form-radio>
                                    </b-form-radio-group>
                                </b-form-group>
                                <b-button
                                    :disabled="(savePackage.permissions != null && savePackage.name != null && savePackage.name != '') ? false : true"
                                    :variant="(savePackage.name != null && savePackage.name != '') ? 'success' : 'danger' "
                                    @click="createOrUpdateSavedList()"
                                    block
                                >
                                    Save List
                                </b-button>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Lists" active @click="getAvailableLists()">
                        <div class="mx-2">
                            <!-- <b-button 
                                variant="info" 
                                block 
                                @click="getAvailableLists()"
                            >
                                Refresh Available Lists
                            </b-button> -->
                            <h4 v-if="refreshingSavedLists">Refreshing Available Lists</h4>
                            <h4 v-else>Available Lists ({{loadOptions.length}})</h4>
                            <b-form-select class="my-2" v-model="loadIDSelected" :options="loadOptions" @change="loadSelectedList()">
                                <template #first>
                                    <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                                </template>
                            </b-form-select>
                            <span v-if="loadableLists.filter((ll)=>{ return ll.id == loadIDSelected }).length > 0">
                                {{loadableLists.filter((ll)=>{ return ll.id == loadIDSelected })[0].description}}
                            </span>
                            <!-- <br>
                            <span v-if="loadableLists.filter((ll)=>{ return ll.id == loadIDSelected }).length > 0">
                                {{loadableLists.filter((ll)=>{ return ll.id == loadIDSelected })[0].id}}
                            </span> -->
                            <!-- <b-button 
                                variant="info" 
                                :disabled="(loadIDSelected == null) ? true : false" 
                                block 
                                @click="loadSelectedList()"
                            >
                                Load List
                            </b-button> -->
                        </div>
                    </b-tab>
                </b-tabs>
        </b-nav>
        <div id="lb-sidebar-container" :class="(sidebarOpen) ? 'lb-sb-cont-open' : 'lb-sb-cont-closed'">
        </div>
        <div :class="(sidebarOpen) ? 'centered-expand-control xpand-open' : 'centered-expand-control xpand-close'">
            <i v-if="sidebarOpen" class="material-icons drop-down xpander-icon xpander-icon-expanded" @click="toggleNav">arrow_back_ios</i>
            <i v-else class="material-icons drop-down xpander-icon xpander-icon-closed" @click="toggleNav">arrow_forward_ios</i>
        </div>
        <div id="table-holder" :class="(sidebarOpen) ? 'lb-th-sb-open' : 'lb-th-sb-closed'">
            <b-row ref="progressAndSpinner" v-if="processingUpdate">
                <b-col cols="12" class="text-center">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                </b-col>
            </b-row>
            <b-row ref="filterRow" class="bg-secondary text-white rounded" no-gutters>
                <b-button class="float-left px-0 mr-1 py-1" variant="dark" v-b-toggle="'colapse-filter'" @click="closeResults">
                    <span class="align-middle float-left when-open">
                        <i class="material-icons drop-down">expand_more</i>
                    </span>
                    <span class="align-middle float-left when-closed">
                        <i class="material-icons drop-down">chevron_right</i>
                    </span>
                </b-button>
                <b-button v-if="filterVis" class="float-left px-1 mr-1 py-1" variant="success" @click="openFilterModalForAdd()">
                    <span class="align-middle float-left when-open">
                        Add Field
                    </span>
                </b-button>
                <b-button v-if="filterVis && $store.getters.getListBuilderSettings.allow_grouping" class="float-left px-1 mr-1 py-1" variant="info" @click="addFilterGroup()">
                    <span class="align-middle float-left when-open">
                        Add Group
                    </span>
                </b-button>
                <span class="float-left text-match-drop-down align-middle">Filter <b-badge variant="light">{{items.length}} Records</b-badge></span>
            </b-row>
            <b-collapse ref="filterCollapse" id="colapse-filter" accordion="my-accordion" v-model="filterVis" v-if="!updatingNestedFilter">
                <nested-filter :filterElems="filterElems" :depth="0" :availableFilterFields="availableFilterFields" :allFields="rawAvailableFields" :filterOperatorsPerType="filterOperatorsPerType" :modifiedCallback="filterChange"></nested-filter>
            </b-collapse>
            <b-row ref="mapRow" class="bg-secondary text-white rounded" align-v="center" no-gutters>
                <b-col >
                    <b-button class="float-left px-0 mr-1 py-1" variant="dark" v-b-toggle="'collapse-map'" @click="delayedUpdateMapSize">
                        <span class="align-middle float-left when-open">
                            <i class="material-icons drop-down">expand_more</i>
                        </span>
                        <span class="align-middle float-left when-closed">
                            <i class="material-icons drop-down">chevron_right</i>
                        </span>
                    </b-button>
                    <span class="float-left text-match-drop-down align-middle">Map</span>
                    <span class="float-left text-match-drop-down align-middle ml-3" v-if="mapSkippedForMissingGPS > 0">
                        <b-badge>
                            {{mapSkippedForMissingGPS}} no lat/lng
                        </b-badge>
                    </span>
                </b-col>
                <b-col>
                    <b-form-select v-model="mapSelectedFeature" :options="availableMappableFeatures" size="sm" class="mt-1 float-left" ></b-form-select>
                </b-col>
                <b-col class="text-right">
                    Color: 
                </b-col>
                <b-col>
                    <b-form-select v-model="mapColorBy" :options="availableForColoring" size="sm" class="mt-1 float-left" @change="packAllDataIntoGeoJSON()"></b-form-select>
                </b-col>
                <b-col>

                </b-col>
            </b-row>
            <b-collapse ref="mapCollapse" id="collapse-map" accordion="my-accordion" v-model="mapCollapseVis">
                <div id="mapContainer"></div>
            </b-collapse>
            <b-row ref="resultRow" class="bg-secondary text-white rounded" align-v="center" no-gutters>
                <b-col>
                    <b-button class="float-left px-0 mr-1 py-1" variant="dark" @click="toggleTableVis">
                        <span v-if="!resultCollapseVis" class="align-middle float-left">
                            <i class="material-icons drop-down">expand_more</i>
                        </span>
                        <span v-else class="align-middle float-left when-closed">
                            <i class="material-icons drop-down">chevron_right</i>
                        </span>
                    </b-button>
                    <span class="float-left text-match-drop-down align-middle">Results</span>
                </b-col>
            </b-row>
            <!-- <b-collapse ref="resultCollapse" id="collapse-results" accordion="my-accordion" v-model="resultCollapseVis">
                
            </b-collapse> -->
            <div id="collapse-results" ref="mugen-wrapper" v-show="!resultCollapseVis">
                <table class="table table-striped" id="lb-table" >
                    <thead class="thead-dark sticky-header lb-table-th" >
                        <draggable v-model="fields" tag="tr">
                            <th class="delimited py-0 px-0 align-middle " v-for="header in fields" :key="header.id" scope="col" :class="(header.name == sortBy) ? 'green-th': ''">
                                <div class="movable drag-indicator header-inline align-middle">
                                    <i class="material-icons">drag_indicator</i>
                                </div>
                                <div class="text-center pl-2 pr-2 header-inline" @click="setSortBy(header)">
                                    {{ header.name }}
                                </div>
                                <div class="header-inline" @click="setSortBy(header)">
                                    <b-icon-dash v-if="sortBy != `${header.name}`"></b-icon-dash>
                                    <b-icon-arrow-down v-else-if="sortBy == `${header.name}` && sortDesc"></b-icon-arrow-down>
                                    <b-icon-arrow-up v-else-if="sortBy == `${header.name}` && !sortDesc"></b-icon-arrow-up>
                                </div>
                                <b-dropdown 
                                    id="dropdown-1" 
                                    class="header-inline act-like-button" 
                                    :variant="(!!header.search || header.selections.length > 0) ? 'success' : 'secondary'">
                                    <b-row class="header-dropdown">
                                        <b-col cols="12">
                                            <b-form-group label="Search This Column" :label-for="`dropdown-form-term-${header.dataName}`" @submit.stop.prevent>
                                                <b-input-group size="sm" class="mb-2">
                                                    <b-form-input
                                                        :id="`dropdown-form-term-${header.dataName}`"
                                                        v-model="header.search"
                                                        placeholder=""
                                                    ></b-form-input>
                                                    <b-input-group-append>
                                                        <b-button variant="success" @click="debouncedLocalUpdate()">Search</b-button>
                                                        <b-button variant="warning" @click="clearHeaderSearch(header)">Clear Search</b-button>
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </b-form-group>
                                            <b-dropdown-divider></b-dropdown-divider>
                                            <b-dropdown-header v-if="headerOptions[header.name] != undefined">
                                                <div class="d-flex flex-row">
                                                    <div class="align-self-center">
                                                        Available Options - {{headerOptions[header.name].length}} Options
                                                    </div>
                                                    <b-button class="ml-2" variant="warning" size="sm" @click="clearColumnSelections(header)">Clear Selections</b-button>
                                                </div>
                                                
                                            </b-dropdown-header>
                                            <b-form-checkbox-group
                                                :id="`dropdown-form-checkboxes-${header.name}`"
                                                v-model="header.selections"
                                                stacked
                                            >
                                                <div class="header-available-options-list">
                                                    <b-form-checkbox 
                                                        class="even-odd-checkbox-rows"
                                                        v-for="(opt, idx) in headerOptions[header.name]" :key="header.dataName + '-' + idx" 
                                                        :value="opt.val"
                                                        @change="debouncedLocalUpdate()"
                                                    >
                                                        {{opt.val}} : {{opt.count}}
                                                    </b-form-checkbox>
                                                </div>
                                            </b-form-checkbox-group>
                                        </b-col>
                                    </b-row>
                                </b-dropdown>
                            </th>
                        </draggable>
                    </thead>
                    <tbody>
                        <tr v-for="item in limitedItemsForDisplay" :key="item.itemID">
                            <td v-for="header in fields" :key="header.id + '-' + item.itemID" class="lb-table-td">
                                <span class="mx-0 my-0" v-if="!header.special">{{ item[header.name] }}</span>
                                <span class="mx-0 my-0" v-else-if="header.type == 'attachment' && item[header.name] != null && item[header.name] != undefined" >
                                    <b-button v-if="item[header.name].type == 'official'" @click="getOfficialPDF(item[header.name].file_id, item[header.name].name)">Download Official {{header.parent}}</b-button>
                                    <b-button v-else-if="item[header.name].type == 'unofficial'" @click="downloadPDF(item[header.name].file_id)">Download Draft {{header.parent}}</b-button>
                                </span>
                                <span class="mx-0 my-0" v-else-if="header.type == 'photo' && item[header.name] != null && item[header.name] != undefined" >
                                    <hover-image-with-modal :displaySize="photoThumbnailSize" :imgID="item[header.name]"></hover-image-with-modal>
                                </span>
                                <span class="mx-0 my-0" v-else-if="header.type == 'link' && item[header.name] != null && item[header.name] != undefined" >
                                    <b-link target="_blank" :to="getFormLink(item[header.name], header.parent.toLowerCase() + 's')">View {{header.parent}}</b-link>
                                </span>
                            </td>
                        </tr>
                        <tr v-if="moreThanLimitItems">
                            <td :colspan="fields.length" class="more-than-row">
                                <mugen-scroll :handler="mugenFetchNext" scroll-container="mugen-wrapper">
                                    <div class="d-flex justify-content-center align-items-center mt-4 overlay-top">
                                        <b-spinner small type="grow" class="mr-1" variant="vai-main-grey"></b-spinner>
                                        <b-spinner type="grow" variant="vai-main-orange"></b-spinner>
                                        <b-spinner small type="grow" class="ml-1" variant="vai-main-grey"></b-spinner>
                                    </div>
                                    <div class="d-flex justify-content-center h3 mt-4">
                                        <span>Currently Showing {{this.curTop}} of {{this.items.length}} - Loading More</span>
                                    </div>
                                </mugen-scroll>
                            </td>
                        </tr>
                    </tbody>
                </table>
                
            </div>
        </div>
        <b-modal 
            ref="filter-modal" 
            :title="filterModal.title"
            hide-footer
        >
            <b-form-group
                id="filter-modal-selected-field-group"
                description="Field must be on list or from one of the groups which has a field on the list."
                label="Select A Field"
                label-for="filter-modal-selected-field"
            >
                <b-form-select id="filter-modal-selected-field" v-model="filterModal.selectedField" :options="availableFilterFields" @change="updateFilterAvailableCompareOptionsAndFieldType()"></b-form-select>
            </b-form-group>
            <b-form-group v-if="filterModal.selectedField != null"
                id="filter-modal-operator-group"
                label="Operator"
                label-for="filter-modal-operator"
            >
                <!-- <b-form-select id="filter-modal-operator" v-model="filterModal.compareOperator" :options="filterOperatorsPerType[rawAvailableFields.filter((avl)=>{ return avl.id == filterModal.selectedField })[0].type]"></b-form-select> -->
                <b-form-select id="filter-modal-operator" v-model="filterModal.compareOperator" :options="filterModal.operatorOptions"></b-form-select>
            </b-form-group>
            <b-form-group v-if="filterModal.compareOperator != null && (filterModal.selectedFieldType == 'text' || filterModal.selectedFieldType == 'text[]') && filterModal.compareOperator != 'NULL' && filterModal.compareOperator != 'NOTNULL' && filterModal.compareOperator != 'ARRAYLENGTHATLEASTONE'"
                id="filter-modal-input-group"
                label="Input"
                label-for="filter-modal-input"
            >
                <b-form-input :type="'text'" id="filter-modal-input" v-model="filterModal.compareTo"></b-form-input>
            </b-form-group>
            <b-form-group v-if="filterModal.compareOperator != null && filterModal.selectedFieldType == 'numeric' && filterModal.compareOperator != 'NULL' && filterModal.compareOperator != 'NOTNULL'"
                id="filter-modal-input-group"
                label="Input"
                label-for="filter-modal-input"
            >
                <b-form-input :type="'number'" id="filter-modal-input" v-model="filterModal.compareTo"></b-form-input>
            </b-form-group>

            <b-form-group v-if="filterModal.compareOperator != null && filterModal.selectedFieldType == 'integer' && filterModal.compareOperator != 'NULL' && filterModal.compareOperator != 'NOTNULL'"
                id="filter-modal-input-group"
                label="Input"
                label-for="filter-modal-input"
            >
                <b-form-input :type="'number'" id="filter-modal-input" v-model="filterModal.compareTo"></b-form-input>
            </b-form-group>

            <b-form-group v-if="filterModal.compareOperator != null && filterModal.selectedFieldType == 'timestamp with time zone' && (filterModal.compareOperator == 'OLDERTHANDAYSAGO' || filterModal.compareOperator == 'YOUNGERTHANDAYSAGO')"
                id="filter-modal-input-group"
                label="Input"
                label-for="filter-modal-input"
            >
                <b-input-group :prepend="(filterModal.compareOperator == 'OLDERTHANDAYSAGO') ? 'more than' : 'within the last'" :append="(filterModal.compareOperator == 'OLDERTHANDAYSAGO') ? 'days ago' : 'days'">
                    <b-form-input :type="'number'" id="filter-modal-input" min="1" max="10000" v-model="filterModal.compareTo"></b-form-input>
                </b-input-group>
                
            </b-form-group>
            <b-form-group v-if="filterModal.compareOperator != null && filterModal.selectedFieldType == 'timestamp with time zone' && filterModal.compareOperator != 'OLDERTHANDAYSAGO' && filterModal.compareOperator != 'YOUNGERTHANDAYSAGO' && filterModal.compareOperator != 'LASTMONTH' && filterModal.compareOperator != 'LAST90DAYS' && filterModal.compareOperator != 'LASTYEAR' && filterModal.compareOperator != 'THISYEAR' && filterModal.compareOperator != 'NULL' && filterModal.compareOperator != 'NOTNULL'"
                id="filter-modal-input-group"
                label="Input"
                label-for="filter-modal-input"
            >
                <b-form-input :type="'date'" id="filter-modal-input" v-model="filterModal.compareTo"></b-form-input>
            </b-form-group>
            <b-form-group v-if="filterModal.compareOperator != null && filterModal.selectedFieldType == 'boolean' && filterModal.compareOperator != 'NULL' && filterModal.compareOperator != 'NOTNULL'"
                id="filter-modal-input-group"
                label="Input"
                label-for="filter-modal-input"
            >
                <b-form-select id="filter-modal-input" :options="booleanCompareToOptions" v-model="filterModal.compareTo"></b-form-select>
            </b-form-group>
            <hr>
            
            <b-button 
                :disabled="filterModal.compareTo == null && filterModal.compareOperator != 'LASTMONTH' && filterModal.compareOperator != 'LAST90DAYS' && filterModal.compareOperator != 'LASTYEAR' && filterModal.compareOperator != 'THISYEAR' && filterModal.compareOperator != 'NULL' && filterModal.compareOperator != 'NOTNULL' && filterModal.compareOperator != 'ARRAYLENGTHATLEASTONE'" 
                class="float-right ml-2" 
                variant="success" 
                @click="doneFilterElement()"
            >
                {{filterModal.okTitle}}
            </b-button>
            <b-button class="float-right" variant="warning" @click="closeFilterModal">Cancel</b-button>
        </b-modal>
        <b-modal 
            ref="pivot-creation-modal" 
            title="Configure Pivot Table"
            size="lg"
            hide-footer
        >
            <b-form-group
                id="pivot-creation-modal-vertical-group"
                description="Field must be a header on the current table"
                label="Select a row field for which you want information for each distinct value"
                label-for="pivot-creation-modal-vertical-field"
            >
                <b-form-select id="pivot-creation-modal-vertical-field" v-model="pivotModal.vertField" :options="availablePivotFields"></b-form-select>
            </b-form-group>
            <b-form-group
                id="pivot-creation-modal-horizontal-group"
                description="Field must be a header on the current table"
                label="Select something to count"
                label-for="pivot-creation-modal-horizontal-field"
            >
                <b-form-select id="pivot-creation-modal-horizontal-field" v-model="pivotModal.horizField" :options="availablePivotFields"></b-form-select>
            </b-form-group>
            <hr>
            
            <b-button :disabled="pivotModal.horizField == null || pivotModal.vertField == null" class="float-right ml-2" variant="success" @click="generatePivotTable()">Generate Pivot Table</b-button>
            <b-button class="float-right" variant="warning" @click="closePivotModal">Cancel</b-button>
        </b-modal>
        <b-modal 
            ref="pivot-table-display-modal" 
            title="Custom Pivot Table"
            size="lg"
            hide-footer
        >
            <b-table sticky-header striped hover head-variant="dark" :items="pivotModal.resultPivot" :fields="pivotModal.pivotTableFields"></b-table>
            <b-button class="float-right ml-2" variant="info" v-if="$store.getters.checkUIPC({path: ['listBuilder', 'actions', 'canDownloadCSV'] })" @click="downloadPivotCSV()">Download Pivot Table CSV</b-button>
            <b-button class="float-right" variant="success" @click="closePivotTableDisplayModal">Done</b-button>
        </b-modal>
        <b-modal 
            ref="pre-action-warn-not-saved" 
            title="Before Running Bulk Action"
            hide-footer
        >
            <div class="d-block text-center">
                <h3>{{preActionModal.body}}</h3>
                <p>{{preActionModal.subText}}</p>
            </div>
            <b-button v-if="preActionModal.okEnabled" class="float-right ml-2" variant="success" @click="preActionModal.onOK()">{{preActionModal.okName}}</b-button>
            <b-button class="float-right" variant="warning" @click="preActionModal.onCancel()">Nevermind</b-button>
        </b-modal>
        <b-modal 
            ref="loading-modal" 
            title="Loading Data"
            size="lg"
            hide-footer
        >
            <div class="d-flex justify-content-center align-items-center mt-4 overlay-top">
                <b-spinner small type="grow" class="mr-1" variant="vai-main-grey"></b-spinner>
                <b-spinner type="grow" variant="vai-main-orange"></b-spinner>
                <b-spinner small type="grow" class="ml-1" variant="vai-main-grey"></b-spinner>
            </div>
            <div class="d-flex justify-content-center h3 mt-4">
                <span>{{loadingStatus}}</span>
            </div>
            <div>
                <b-progress :max="loadingMax" height="2rem">
                    <b-progress-bar :value="currentLoadingProcess">
                        <span>Progress: <strong>{{ currentLoadingProcess.toFixed(2) }} / {{ loadingMax }}</strong></span>
                    </b-progress-bar>
                </b-progress>
            </div>
            <b-button class="float-right mt-3" variant="warning" @click="cancelWhileLoading">Cancel</b-button>
        </b-modal>

    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 300000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');
const stringify = require('csv-stringify')
const fileDownload = require('js-file-download');
const butils = require('../libs/basicUtils.js');
import draggable from 'vuedraggable'
import NestedFilter from '@/components/NestedFilter.vue'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Image from '@/components/subcomponents/Image.vue';
import MugenScroll from 'vue-mugen-scroll';
const { glify } = require('leaflet.glify');

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
    name: 'listbuilder',
    components:{
        draggable,
        'nested-filter': NestedFilter,
        'hover-image-with-modal': Image,
        'mugen-scroll': MugenScroll
    },
    props:{
        listID: {
            type: String,
            default: () => { return null }
        },
    },
    data(){
        this.map = null;
        this.items = [];
        this.rawItems = [];
        this.leafGeoHandle = null;
        this.tileProviders = [
            {
                name: "MapBox - Light",
                visible: true,
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                url: "https://api.mapbox.com/styles/v1/trichards140/ckc2i4uee044v1ilzl8snvp2s/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJpY2hhcmRzMTQwIiwiYSI6ImNrYnllYzlicTBiY2IyeHQ2NGZreDU2ZjQifQ.PVvaJNTzI9zQReH1CYsteQ"
            },
            {
                name: "MapBox - Satelite Streets V11",
                visible: false,
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                url: "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJpY2hhcmRzMTQwIiwiYSI6ImNrYnllYzlicTBiY2IyeHQ2NGZreDU2ZjQifQ.PVvaJNTzI9zQReH1CYsteQ"
            },
            {
                name: "MapBox - Streets V11",
                visible: false,
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                url: "https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJpY2hhcmRzMTQwIiwiYSI6ImNrYnllYzlicTBiY2IyeHQ2NGZreDU2ZjQifQ.PVvaJNTzI9zQReH1CYsteQ"
            }
            
        ]
        return{
            axiosController: null,
            loadingStatus: "Server Processing",
            currentDotBounds: null,
            mugenLoading: false,
            refreshingSavedLists: false,
            // Sidebar Stuff
            sidebarOpen: true,
            // END Sidebar Stuff
            // Map Stuff
            geoJSONReady: false,
            tileLayer: null,
            tilerReady: false,
            mapColorBy: null,
            mapCollapseVis: false,
            resultCollapseVis: true,
            mapSelectedFeature: null,
            mapShowingDots: 0,
            mapSkippedForMissingGPS: 0,
            mapID: null,
            zoom: 8,
            center: [27.936177185910104, -82.70507812500001],
            somethingSelected: false,
            selected: {},
            accessToken: "pk.eyJ1IjoidHJpY2hhcmRzMTQwIiwiYSI6ImNrYnllYzlicTBiY2IyeHQ2NGZreDU2ZjQifQ.PVvaJNTzI9zQReH1CYsteQ",
            keyLegendControl: null,
            // End Map Stuff
            photoThumbnailSize: 128,
            photoThumbnailSizeOptions: [
                { text: 'Tiny', value: 64 },
                { text: 'Small', value: 128 },
                { text: 'Medium', value: 192 },
                { text: 'Large', value: 256 },
            ],
            updatingNestedFilter: false,
            resultsVis: true,
            waiting: false,
            processingUpdate: false,
            loadedFromPackage: null,
            savePackage:{
                id: null,
                name: null,
                description: null,
                disabled: false,
                permissions: 'user'
            },
            loadableLists: [],
            loadIDSelected: null,
            loadOptions: [],
            tree: [],
            rawAvailableFields: [],
            fields: [],
            headerOptions: {},
            setLimit: 50,
            moreThanLimitItems: false,
            limitedItemsForDisplay: [],
            dragging: false,
            filterVis: true,
            testVis: true,
            filterElems: [],
            filterModal: {
                parentIndex: 0,
                editingIndex: 0,
                title: null,
                okTitle: null,
                selectedField: null,
                compareOperator: null,
                compareTo: null,
                operatorOptions: [],
                selectedFieldType: null
            },
            pivotModal: {
                pivotTableFields: [],
                resultPivot: [],
                vertField: null,
                horizField: null
            },
            filterOperatorsPerType: {
                text: [
                    { text: 'equals', value: 'EQUAL'},
                    { text: 'not equals', value: 'NOTEQUAL'},
                    { text: 'is blank', value: 'NULL'},
                    { text: 'is not blank', value: 'NOTNULL'},
                    { text: 'contains (case-insensitive)', value:'CONTAINS'},
                    { text: 'does not contain (case-insensitive)', value:'NOTCONTAINS'}
                ],
                numeric: [
                    { text: 'equals', value: 'EQUAL'},
                    { text: 'not equals', value: 'NOTEQUAL'},
                    { text: 'less than', value: 'LESS'},
                    { text: 'less than or equal to', value: 'LESSEQU'},
                    { text: 'greater than', value: 'GREATER'},
                    { text: 'greater than or equal to', value: 'GREATEREQU'},
                    { text: 'is blank', value: 'NULL'},
                    { text: 'is not blank', value: 'NOTNULL'}
                ],
                integer: [
                    { text: 'equals', value: 'EQUAL'},
                    { text: 'not equals', value: 'NOTEQUAL'},
                    { text: 'less than', value: 'LESS'},
                    { text: 'less than or equal to', value: 'LESSEQU'},
                    { text: 'greater than', value: 'GREATER'},
                    { text: 'greater than or equal to', value: 'GREATEREQU'},
                    { text: 'is blank', value: 'NULL'},
                    { text: 'is not blank', value: 'NOTNULL'}
                ],
                'timestamp with time zone':[
                    { text: 'equals', value: 'EQUAL'},
                    { text: 'not equals', value: 'NOTEQUAL'},
                    { text: 'less than', value: 'LESS'},
                    { text: 'less than or equal to', value: 'LESSEQU'},
                    { text: 'greater than', value: 'GREATER'},
                    { text: 'greater than or equal to', value: 'GREATEREQU'},
                    { text: 'more than X days ago', value: 'OLDERTHANDAYSAGO'},
                    { text: 'within the last X days', value: 'YOUNGERTHANDAYSAGO'},
                    { text: 'last month', value: 'LASTMONTH'},
                    { text: 'last 90 days', value: 'LAST90DAYS'},
                    { text: 'last year', value: 'LASTYEAR'},
                    { text: 'this year', value: 'THISYEAR'},
                    { text: 'is blank', value: 'NULL'},
                    { text: 'is not blank', value: 'NOTNULL'}
                    
                ],
                'text[]':[
                    { text: 'includes the element (case-sensitive)', value: 'ARRAYCONTAINS'},
                    { text: 'includes an element like (case-insensitive)', value: 'ARRAYCONTAINSLIKE'},
                    { text: 'NOT including the element (case-sensitive)', value: 'ARRAYNOTCONTAINS'},
                    { text: 'NOT including an element like (case-insensitive)', value: 'ARRAYNOTCONTAINSLIKE'},
                    { text: 'Contains At Least One Element', value: 'ARRAYLENGTHATLEASTONE'},
                ],
                boolean: [
                    { text: 'is', value: 'EQUAL'},
                ]
            },
            booleanCompareToOptions: [
                { text: 'True', value: 'TRUE' },
                { text: 'False', value: 'FALSE' },
                { text: 'NULL (not applicable to all boolean fields)', value: 'NULL' }
            ],
            combinationOptions: [
                { text: 'AND', value: 'AND'},
                { text: 'OR', value: 'OR', disabled: !this.$store.getters.getListBuilderSettings.allow_or}
            ],
            sortBy: null,
            sortHeader: null,
            sortDesc: false,
            sortFunction: (a,b)=>{
                return (a < b);
            },
            currentlySortedBy: null,
            baseFileURL: process.env.VUE_APP_FILE_API_BASE_URL,
            downloadToDoList: [],
            resultHeight: '250px',
            changedSinceLastSave: false,
            preActionModal: {
                body: '',
                subText: '',
                okName: 'Save Changes & Continue',
                okEnabled: true,
                onOK: null,
                onCancel: null
            },
            // Mugen Loader
            curTop: 0,
            chunkSize: 50,
            // Loading Modal
            loadingModalShowProgressBar: false,
            currentLoadingProcess: 0,
            loadingMax: 100,
        }
    },
    methods: {
        parentAllowed(parent, providedSelectedParentNames = null, applyChanges = true){
            // Installs & Replacements is not allowed when Device or Test or Survey is selected
            var selectedParents = [];
            var parentNames = [];
            if(providedSelectedParentNames == null){
                selectedParents = _.filter(this.tree, (par)=>{
                    let selectedChildren = _.filter(par.children, (child)=>{
                        return child.selected;
                    });
                    return (selectedChildren.length > 0);
                })
                parentNames = selectedParents.map((par)=>{ return par.name });
            }else{
                parentNames = providedSelectedParentNames;
            }
            
            
            if(parent.name == "Installs & Replacements"){
                // Not Allowed When Device, Test, or Survey Is Selected
                let notAllowed = ["Device", "Test", "Survey"]
                let intersect = _.intersection(parentNames, notAllowed);
                if(intersect.length > 0){
                    // Disable This Option
                    if(applyChanges){
                        parent.showInfo = true;
                        parent.infoValue = `Installs & Replacements is associated with the Connection and is not available when any fields from Device, Test, or Survey are selected.`;
                    }
                    return false;
                }
                if(parentNames.length > 0 && !parentNames.includes("Connection")){
                    if(applyChanges){
                        parent.showInfo = true;
                        parent.infoValue = `Installs & Replacements is not available. Installs & Replacements are associated with Connection. Connection must be selected.`;
                    }
                    return false;
                }
            }else if(parent.name == "Survey" || parent.name == "Test" || parent.name == "Device"){
                let conflictedParentNames = _.without(parentNames, "Test", "Survey", "Device")
                // Not Allowed When Installs & Replacements Is Selected
                if(parentNames.includes("Installs & Replacements")){
                    if(applyChanges){
                        parent.showInfo = true;
                        parent.infoValue = `${parent.name} is not available when any fields from Installs & Replacements are selected.`;
                    }
                    return false;
                }
                if(parent.name == "Survey" && parentNames.includes("Test")){
                    if(applyChanges){
                        parent.showInfo = true;
                        parent.infoValue = `${parent.name} is not available. Tests is already selected. Only one event type is allowed at a time.`;
                    }
                    return false;
                }
                if(parent.name == "Test" && parentNames.includes("Survey")){
                    if(applyChanges){
                        parent.showInfo = true;
                        parent.infoValue = `${parent.name} is not available. Survey is already selected. Only one event type is allowed at a time.`;
                    }
                    return false;
                }
                if((parent.name == "Survey" || parent.name == "Test") && conflictedParentNames.length > 0 && !parentNames.includes("Device")){
                    if(applyChanges){
                        parent.showInfo = true;
                        parent.infoValue = `${parent.name} is not available. ${parent.name} is associated with Device. Device must be selected.`;
                    }
                    return false;
                }
                if(parent.name == "Device"  && conflictedParentNames.length > 0 && !parentNames.includes("Connection")){
                    if(applyChanges){
                        parent.showInfo = true;
                        parent.infoValue = `${parent.name} is not available. ${parent.name} is associated with Connection. Connection must be selected.`;
                    }
                    return false;
                }
            }
            if(applyChanges){
                parent.showInfo = false;
                parent.infoValue = null;
            }
            return true;
        },
        cancelWhileLoading(){
            this.axiosController.abort();
            this.$refs['loading-modal'].hide();
        },
        closeResults(){
            this.resultCollapseVis = true;
        },
        toggleTableVis(){
            this.filterVis = false;
            this.mapCollapseVis = false;
            this.resultCollapseVis = !this.resultCollapseVis;
        },
        mugenFetchNext(){
            console.log(`Start Fetch Next 50`)
            this.mugenLoading = true;
            var addSet = _.map(this.items.slice(this.curTop,(this.curTop + this.chunkSize)), (add)=>{
                return {
                    ...add,
                    itemID: uuidv4()
                }
            });
            this.limitedItemsForDisplay = this.limitedItemsForDisplay.concat(addSet);
            this.curTop = (this.curTop + this.chunkSize);
            console.log(`Added ${this.chunkSize} Items`)
            if(this.curTop >= this.items.length){
                this.moreThanLimitItems = false;
            }
            this.mugenLoading = false;
            console.log(`Finish Fetch Next 50`)
        },
        setVisibleItems(){
            this.limitedItemsForDisplay = _.map(this.items.slice(0,this.setLimit), (add)=>{
                return {
                    ...add,
                    itemID: uuidv4()
                }
            });
            // this.limitedItemsForDisplay = this.items.slice(0,this.setLimit);
            this.chunkSize = 50;
            this.curTop = this.setLimit;
            if(this.curTop < this.items.length){
                this.moreThanLimitItems = true;
            }
        },
        filterChange(){
            this.changedSinceLastSave = true;
        },
        issueToFulcrum(){
            // Check if list is saved, or has been changed since last save
            var list = this.packList();
            var saveMe = {
                id: this.savePackage.id,
                name: this.savePackage.name,
                description: this.savePackage.description,
                disabled: this.savePackage.disabled,
                account_shared: false,
                allow_account_editing: false,
                justSaved: true,
                list: list
            };
            if(this.savePackage.permissions == 'user'){
                // Dont Change Nothing
            }else if(this.savePackage.permissions == 'account'){
                saveMe.account_shared = true;
            }else if(this.savePackage.permissions == 'accountEdit'){
                saveMe.account_shared = true;
                saveMe.allow_account_editing = true;
            }
            console.log(saveMe)
            console.log(this.loadedFromPackage)
            if(_.isEqual(saveMe, this.loadedFromPackage)){
                // Push to Issue To Fulcrum
                this.$router.push({path: '/home/listbuilder/actions/issuetofulcrum/' + this.savePackage.id });
            }else{
                // Warn user that changes need to be saved, offer to save all changes now
                this.warnNotSaved(this.issueToFulcrum);
            }
        },
        warnNotSaved(completedCallback){
            this.preActionModal.body = '';
            this.preActionModal.subText = '';
            this.preActionModal.okName = 'Save Changes & Continue';
            this.preActionModal.okEnabled = true;
            this.preActionModal.onOK = null,
            this.preActionModal.onCancel = ()=>{
                this.$refs['pre-action-warn-not-saved'].hide();
            };

            if(this.savePackage.id == null){
                // Must be completed manually
                this.preActionModal.body = 'Save List First'
                this.preActionModal.subText = `This action requires that the list be saved. This list is new, and doesn't appear to have ever been saved. Save this list first, then try again.`
                this.preActionModal.okEnabled = false;
                this.preActionModal.onOK = null;
            }else{
                this.preActionModal.body = 'Unsaved Changes'
                this.preActionModal.subText = `This action requires that the list be saved. Some changes have been made since the last time the list was saved. Would you like to save the updates and continue?`
                this.preActionModal.onOK = ()=>{
                    this.createOrUpdateSavedList((result)=>{
                        if(result){
                            // It saved, do the completion callback
                            completedCallback();
                        }else{
                            // It failed to save
                        }
                    })
                }
            }
            this.$refs['pre-action-warn-not-saved'].show();
            
        },
        toggleNav(){
            this.sidebarOpen = !this.sidebarOpen;
            // setTimeout(()=>{
            //     this.mapUpdatePageSize();
            // }, 200)
        },
        downloadAllOfficialReports(){
            
            this.downloadToDoList = [];
            this.items.forEach((itm)=>{
                var report = itm['Test Pdf Report'];
                if(report != null && report != undefined){
                    if(report.type == 'official'){
                        this.downloadToDoList.push(report);
                    }
                }
            })

            // TODO: Initialize some progress bar or something
            this.downloadNextOfficial(0);
        },
        downloadNextOfficial(idx){
            const fileFetchInstance = axios.create({
                timeout: 10000,
                headers: {
                    'Content-Type': 'application/json'
                    },
                crossdomain: true,
                withCredentials: true,
                responseType: 'blob'
            });
            fileFetchInstance.get(process.env.VUE_APP_FILE_API_BASE_URL + '/' + this.downloadToDoList[idx].file_id)
            .then(async (response) => {
                fileDownload(response.data, this.downloadToDoList[idx].name);
                if(idx + 1 < this.downloadToDoList.length){
                    this.downloadNextOfficial(idx + 1)
                }
            })
            .catch((error) => {
                if(error.response.status == 404){
                    console.log("File Not Found");
                }
                console.log(error);
                if(idx + 1 < this.downloadToDoList.length){
                    this.downloadNextOfficial(idx + 1)
                }
            });
        },
        downloadPDF(eventID){
            var jwt = this.$store.getters.getJWT;
            const pdfInstance = axios.create({
                timeout: 10000,
                headers: {
                    'Content-Type': 'application/json'
                    },
                crossdomain: true,
                withCredentials: true,
                responseType: 'blob'
            });
            //Get User Token and Fetch The Values Required For This Page
            pdfInstance.get(process.env.VUE_APP_API_BASE_URL + '/download/pdf/test/' + eventID + '.pdf')
            .then(async (response) => {
                console.log(response);
                var contentDisp = response.headers["content-disposition"];
                if(contentDisp != null){
                    var fileNameIndex = contentDisp.indexOf("filename=");
                    if(fileNameIndex == -1){
                        // Doesnt Have A FileName
                        fileDownload(response.data, 'Test Report ' + this.eventID + '.pdf');
                    }else{
                        // Has A FileName
                        var expectedFileName = contentDisp.match(/(?<=filename=").+(?=")/g)
                        console.log(expectedFileName);
                        if(expectedFileName.length > 0){
                            fileDownload(response.data, expectedFileName[0]);
                        }else{
                            fileDownload(response.data, 'Test Report ' + this.eventID + '.pdf');
                        }
                    }
                }else{
                    // No File Name Specified
                    fileDownload(response.data, 'Test Report ' + this.eventID + '.pdf');
                }
                
            })
            .catch((error) => {
                if(error.response.status == 404){
                    console.log("Unable To Generate PDF Report");
                }
                console.log(error);
            });
        },
        getOfficialPDF(fileID, name){
            const fileFetchInstance = axios.create({
                timeout: 10000,
                headers: {
                    'Content-Type': 'application/json'
                    },
                crossdomain: true,
                withCredentials: true,
                responseType: 'blob'
            });
            //Get User Token and Fetch The Values Required For This Page
            fileFetchInstance.get(process.env.VUE_APP_FILE_API_BASE_URL + '/' + fileID)
            .then(async (response) => {
                fileDownload(response.data, name);
            })
            .catch((error) => {
                if(error.response.status == 404){
                    console.log("File Not Found");
                }
                console.log(error);
            });
        },
        updateFilterAvailableCompareOptionsAndFieldType(){
            this.filterModal.operatorOptions = [];
            console.log(this.filterModal.selectedField)
            // Find the field by it's id, which is what the filter modal's selection knows
            var matches = this.rawAvailableFields.filter((avl)=>{ return avl.id == this.filterModal.selectedField });
            var selectedMatch = {};
            if(matches.length > 0){
                // Take the first match
                selectedMatch = matches[0];
            }
            this.filterModal.selectedFieldType = selectedMatch.type;
            this.filterModal.operatorOptions = this.filterOperatorsPerType[selectedMatch.type];
        },
        zoomExtent(){
            this.map.fitBounds(this.currentDotBounds);
        },
        setupLeafletMap(){
            var baseMaps = {};
            var layers = [];
            this.tileProviders.forEach((tp)=>{
                var tmp = L.tileLayer(tp.url,{ 
                    maxZoom: 22,
                    attribution: tp.attribution
                })
                layers.push(tmp)
                baseMaps[tp.name] = tmp;
            })

            this.map = L.map('mapContainer',{
                center: this.center,
                zoom: this.zoom,
                layers: [layers[0]]
            });
            L.control.layers(baseMaps).addTo(this.map);
            L.Control.ZoomExtent = L.Control.extend({
                options: {
                    position: 'topleft'
                },

                onAdd: ()=>{
                    var container = L.DomUtil.create('div', 'leaflet-bar leaflet-control material-icons');

                    container.link = L.DomUtil.create('a', 'leaflet-bar-part', container);
                    container.link.title = 'Zoom Extent';
                    var userIcon = L.DomUtil.create('i', 'material-icons', container.link);
                    // console.log(userIcon);
                    userIcon.innerHTML = 'center_focus_strong';
                    userIcon.setAttribute('role', 'presentation');
                    container.link.href = '#';
                    container.link.setAttribute('role', 'button');

                    container.onclick = ()=>{
                        this.zoomExtent();
                    }
                    return container;
                }
            });
            var zoomExtentControl = new L.Control.ZoomExtent();
            this.map.addControl(zoomExtentControl);
        },
        buildMapLegend(){
            if(this.mapColorBy != null){
                if(this.keyLegendControl != null){
                    this.keyLegendControl.remove();
                }
                L.Control.KeyLegend = L.Control.extend({
                    options: {
                        position: 'topleft'
                    },

                    onAdd: ()=>{
                        var container = L.DomUtil.create('div', 'map-legend d-none d-md-block');
                        var draggable = new L.Draggable(container);
                        draggable.enable();
                        this.headerOptions[this.mapColorBy].forEach((opt, index)=>{
                            let color = this.getDistinctColor(index);
                            let singleColor = L.DomUtil.create('div', 'map-legend-single', container);
                            let dot = L.DomUtil.create('div', 'map-legend-dot', singleColor);
                            dot.style.backgroundColor = color;
                            let title = L.DomUtil.create('div', 'map-legend-title', singleColor);
                            title.innerHTML = `${opt.val} (${opt.count})`
                            // console.log(opt);
                        })

                        return container;
                    }
                });
                this.keyLegendControl = new L.Control.KeyLegend();
                this.map.addControl(this.keyLegendControl);
            }else{
                if(this.keyLegendControl != null){
                    this.keyLegendControl.remove();
                }
            }
        },
        packAllDataIntoGeoJSON(){
            
            this.mapUpdatePageSize();
            this.mapShowingDots = 0;
            this.mapSkippedForMissingGPS = 0;
            var geoCount = 0;
            var missingGeo = 0;
            var latMax = -1000;
            var latMin = 1000;
            var lngMax = -1000;
            var lngMin = 1000;
            
            // this.geoJSONReady = false;
            var tmpGeoJSON = {
                type: "FeatureCollection",
                features: []
            };
            if(this.availableMappableFeatures.length != 0 && this.mapSelectedFeature != null){
                var start = Date.now();
                var delta = null;
                this.items.forEach((itm)=>{
                    let curLat = null;
                    let curLng = null;
                    if(itm[this.mapSelectedFeature.lat] != 0 && itm[this.mapSelectedFeature.lat] != null && itm[this.mapSelectedFeature.lat] != undefined){
                        curLat = Number(itm[this.mapSelectedFeature.lat]);
                    }
                    if(itm[this.mapSelectedFeature.lng] != 0 && itm[this.mapSelectedFeature.lng] != null && itm[this.mapSelectedFeature.lng] != undefined){
                        curLng = Number(itm[this.mapSelectedFeature.lng]);
                    }
                    
                    if(curLat != null && curLng != null){
                        if(curLat > latMax){
                            latMax = curLat;
                        }
                        if(curLat < latMin){
                            latMin = curLat;
                        }
                        if(curLng > lngMax){
                            lngMax = curLng;
                        }
                        if(curLng < lngMin){
                            lngMin = curLng
                        }
                        geoCount++;
                        var chosenColor;
                        if(this.mapColorBy != null){
                            let colorIndex = _.findIndex(this.headerOptions[this.mapColorBy], (ho)=>{ return ho.val == itm[this.mapColorBy] });
                            chosenColor = this.getDistinctColorRGB(colorIndex);
                        }else{
                            chosenColor = {
                                r: (240/255),
                                g: (168/255),
                                b: 0,
                                a: 1
                            };
                        }
                        var feat = {
                            type: "Feature",
                            properties: {
                                ...itm, 
                                color: chosenColor,
                            },
                            geometry: {
                                type: "Point",
                                coordinates: [curLat, curLng]
                            }
                        };
                        tmpGeoJSON.features.push(feat);
                    }else{
                        missingGeo++;
                    }
                })
                var corner1 = L.latLng(latMin, lngMin);
                var corner2 = L.latLng(latMax, lngMax);
                this.currentDotBounds = L.latLngBounds(corner1, corner2);
                this.mapShowingDots = geoCount;
                this.mapSkippedForMissingGPS = missingGeo;
                //console.log(tmpGeoJSON);
                if(this.leafGeoHandle != null){
                    this.leafGeoHandle.remove();
                    this.map.removeLayer(this.leafGeoHandle.layer);
                    L.glify.pointsInstances.splice(0, 1);
                    this.leafGeoHandle = null;
                    //this.leafGeoHandle.removeFrom(this.map);
                }
                // this.geoJSON = tmpGeoJSON;
                // Handle Colors
                this.leafGeoHandle = L.glify.points({
                    map: this.map,
                    size: 20,
                    color: (index, point) => {
                        return point.properties.color;
                    },
                    opacity: 1,
                    border: false,
                    click: (clickEvent, feature)=>{
                        L.popup({
                            maxWidth: 800, 
                            minWidth: 300
                        })
                        .setLatLng({
                            lat: feature.geometry.coordinates[0],
                            lng: feature.geometry.coordinates[1]
                        })
                        .setContent(this.createMarkerPopupContent(feature.properties))
                        .openOn(this.map);
                    },
                    data: tmpGeoJSON
                })

                this.buildMapLegend();
                delta = Date.now() - start; // milliseconds elapsed since start
                console.log(`Packing GeoJSON Took: ${Math.floor(delta)} ms`);
            }else{
                // Maybe check if any feature can be selected and use that one
                if(this.availableMappableFeatures.length == 1){
                    this.setMapFeatureName(this.availableMappableFeatures[0].value);
                    this.packAllDataIntoGeoJSON();
                }else{
                    // No Feature Can Be Selected, Therefore, Clear GeoJSON
                    if(this.leafGeoHandle != null){
                        this.leafGeoHandle.remove();
                        this.map.removeLayer(this.leafGeoHandle.layer);
                        L.glify.pointsInstances.splice(0, 1);
                        this.leafGeoHandle = null;
                    }
                }
            }
        },
        getDistinctColor(number) {
            const hue = number * 137.508; // use golden angle approximation
            const hue360 = hue % 360;
            return this.hslToRgbHexCode((hue360/360), 1.0, 0.5)
            return `hsl(${hue},100%,50%)`;
        },
        getDistinctColorRGB(number){
            const hue = number * 137.508; // use golden angle approximation
            const hue360 = hue % 360;
            return this.hslToRgb((hue360/360), 1.0, 0.5);
        },
        hslToRgb(h, s, l){
            var r, g, b;
            if(s == 0){
                r = g = b = l; // achromatic
            }else{
                var hue2rgb = function hue2rgb(p, q, t){
                    if(t < 0) t += 1;
                    if(t > 1) t -= 1;
                    if(t < 1/6) return p + (q - p) * 6 * t;
                    if(t < 1/2) return q;
                    if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
                    return p;
                }

                var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
                var p = 2 * l - q;
                r = hue2rgb(p, q, h + 1/3);
                g = hue2rgb(p, q, h);
                b = hue2rgb(p, q, h - 1/3);
            }

            return {
                r: r,
                g: g,
                b: b,
                a: 1
            };
        },
        hslToRgbHexCode(h, s, l){
            var r, g, b;
            if(s == 0){
                r = g = b = l; // achromatic
            }else{
                var hue2rgb = function hue2rgb(p, q, t){
                    if(t < 0) t += 1;
                    if(t > 1) t -= 1;
                    if(t < 1/6) return p + (q - p) * 6 * t;
                    if(t < 1/2) return q;
                    if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
                    return p;
                }

                var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
                var p = 2 * l - q;
                r = hue2rgb(p, q, h + 1/3);
                g = hue2rgb(p, q, h);
                b = hue2rgb(p, q, h - 1/3);
            }

            return `#${this.componentToHex(Math.round(r * 255))}${this.componentToHex(Math.round(g * 255))}${this.componentToHex(Math.round(b * 255))}`
        },
        componentToHex(c) {
            var hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        },
        createMarkerPopupContent(pt){
            var content = '';
            this.fields.forEach((fld)=>{
                if(fld.type == 'photo' && pt[fld.name] != null && pt[fld.name] != undefined){
                    content += `<div><b>${fld.name}</b>: <br> <img style="height: ${this.photoThumbnailSize}px; width: ${this.photoThumbnailSize}px;" src="${this.baseFileURL}/thumb_${pt[fld.name]}" alt="${fld.name}"> </div>`
                }else if(fld.type == 'link' && pt[fld.name] != null && pt[fld.name] != undefined){
                    content += `<div><b>${fld.name}</b>: <a target="_blank" href="${this.getFormLink(pt[fld.name], fld.parent.toLowerCase() + 's')}">View ${fld.parent}</a></div>`
                }else{
                    content +=  `<div class="popout-wrap-overflow"><b>${fld.name}</b>: ${pt[fld.name]}</div>`
                }
            })
            return content;
        },
        getFormLink(inspectID, isType){
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: isType});
            if(defaultFormForType == null){
                return '.'
            }else if(defaultFormForType.length == 0){
                return '.'
            }else{
                return '/home/form/' + defaultFormForType[0].id + '/' + inspectID
            }
        },
        delayedUpdateMapSize(){
            this.closeResults();
            setTimeout(()=>{
                this.mapUpdatePageSize();
            }, 500)
        },
        setMapFeatureName(feature){
            this.mapSelectedFeature = feature;
        },
        mapUpdatePageSize(){
            if(this.map != null){
                this.map.invalidateSize();
                try {
                    // This is such a beautiful work around that I think I'm gonna keep it
                    L.glify.pointsInstances[0].layer._redraw();
                } catch (error) {

                }
            }
        },
        zoomUpdated (zoom) {
            this.zoom = zoom;
        },
        centerUpdated (center) {
            this.center = center;
        },
        boundsUpdated (bounds) {
            this.bounds = bounds;
        },
        clearHeaderSearch(header){
            header.search = null;
            this.localTableDataUpdate();
        },
        clearColumnSelections(header){
            header.selections = [];
            this.localTableDataUpdate();
        },
        localTableDataUpdate(callback = null){
            this.processingUpdate = true;
            // Make copy of items
            var tmpItems = _.clone(this.rawItems);
            // Fitler Rows By Header
            // Consider both the search term and the 
            this.fields.forEach((header)=>{
                var hasSearch = false;
                var searchRegex = null;
                var hasSelections = false;
                if(_.has(header, 'search')){
                    if(header.search != null && header.search != ''){
                        // Search Contains Something, We should be looking for that
                        hasSearch = true;
                        searchRegex = new RegExp(header.search,'i');
                    }
                }
                if(_.has(header, 'selections')){
                    if(header.selections != null && header.selections.length > 0){
                        hasSelections = true;
                    }
                }
                if(hasSelections || hasSearch){
                    tmpItems = _.filter(tmpItems, (itm, idx)=>{ 
                        var match = false;
                        if(hasSearch){
                            if( itm[header.name] != null && itm[header.name].match(searchRegex) != null){
                                match = true;
                            }
                        }
                        if(hasSelections){
                            if( header.selections.includes(itm[header.name]) ){
                                match = true;
                            }
                        }
                        return match;
                    })
                }
            })
            // Update the items
            this.items = tmpItems;
            // Perform the sortBy
            this.performSortBy();
            this.processingUpdate = false;
            this.packAllDataIntoGeoJSON();
            if(callback != null){
                callback();
            }
        },
        calculateAvailableHeaderOptions(){
            this.headerOptions = {};
            this.fields.forEach((header)=>{
                this.headerOptions[header.name] = this.availableOptionsForHeader(header);
            })
        },
        availableOptionsForHeader(header){
            if(header.type.includes("[]")){
                return [{ val: 'Item Select Unavailable For Array Values', count: '--' }]
            }
            var preCheck = {};
            var numToCheck = Math.min(this.items.length,250);
            var preCheckArray = [];
            for (let i = 0; i < numToCheck; i++) {
                const element = this.items[ _.random(0, Math.max(this.items.length - 1, numToCheck-1) ) ]
                preCheckArray.push(element);
            }
            preCheckArray.forEach((itm)=>{
                if( _.has(preCheck, itm[header.name]) ){
                    preCheck[itm[header.name]]++;
                }else{
                    preCheck[itm[header.name]] = 1;
                }
            })

            const maxUniqueInCheckable = 50;
            var preCheckUnique = _.keys(preCheck);
            if(preCheckUnique.length > maxUniqueInCheckable){
                return [{ val: 'Too Many Unique Values', count: '--' }]
            }else{
                var notUnique = _.pluck(this.items, header.name)
                var unique = _.uniq(notUnique);
                 var counts = _.countBy(notUnique, (itm)=>{
                    return itm;
                })
                // Create the list
                var ret = [];
                unique.sort();
                unique.forEach((val)=>{
                    ret.push( { val: val, count: counts[val] })
                })
                return ret;
            }
        },
        setSortBy(header){
            if(header.name == this.sortBy){
                this.sortDesc = !this.sortDesc;
            }else{
                this.sortBy = header.name;
            }
            this.performSortBy();
        },
        performSortBy(){
            this.changedSinceLastSave = true;
            this.processingUpdate = true;
            if(this.sortBy != null){
                if(this.currentlySortedBy == this.sortBy){
                    this.items = this.items.reverse();
                    this.setVisibleItems();
                    this.processingUpdate = false;
                }else{
                    // BUG: This can fail when more than one header has the same name
                    let match = _.find(this.fields, (fld)=>{ return fld.name == this.sortBy; })
                    // If the sort field is missing, short circuit and warn
                    if(match == undefined){
                        this.sortHeader = null;
                        this.sortBy = null;
                        butils.createToast(this, "Sorting Order Cleared (Field Removed)", "The field that the list was sorted by was removed, the sort has been cleared", "warning", 10);
                        this.currentlySortedBy = null;
                        this.setVisibleItems();
                        this.processingUpdate = false;
                    }else{
                        this.sortHeader = match;
                        if(this.sortHeader.type == 'numeric' || this.sortHeader.type == 'integer' || this.sortHeader.type == 'bigint'){
                            this.sortFunction = (a,b)=>{
                                // Compare a < b
                                let aNull = ( _.isNaN(a[this.sortBy]) || a[this.sortBy] == null || a[this.sortBy] == '');
                                let bNull = ( _.isNaN(a[this.sortBy]) || b[this.sortBy] == null || b[this.sortBy] == '');
                                if( aNull && bNull ){
                                    return 0;
                                }else if( aNull ){
                                    return -1;
                                }else if( bNull ){
                                    return 1;
                                }else{
                                    let aNum = Number(a[this.sortBy]);
                                    let bNum = Number(b[this.sortBy]);
                                    if(aNum == bNum){
                                        return 0;
                                    }else{
                                        return (aNum < bNum) ? -1 : 1;
                                    }
                                    
                                }
                            }; 
                        }else if(this.sortHeader.type == 'text' || this.sortHeader.type == 'uuid' || this.sortHeader.type == 'timestamp with time zone' || this.sortHeader.type == 'character' || this.sortHeader.type == 'date'){
                            this.sortFunction = (a,b)=>{
                                // Compare a < b
                                let aNull = (a[this.sortBy] == null || a[this.sortBy] == '');
                                let bNull = (b[this.sortBy] == null || b[this.sortBy] == '');
                                if( aNull && bNull ){
                                    return 0;
                                }else if( aNull ){
                                    return -1;
                                }else if( bNull ){
                                    return 1;
                                }else{
                                    let res = a[this.sortBy].localeCompare(b[this.sortBy]);
                                    if(res == 0){
                                        return 0;
                                    }else{
                                        return (res < 0) ? -1 : 1;
                                    }
                                }
                            }; 
                        }else if(this.sortHeader.type == 'text[]'){
                            this.sortFunction = (a,b)=>{
                                // Compare a < b
                                let aNull = (a[this.sortBy] == null || a[this.sortBy] == '');
                                let bNull = (b[this.sortBy] == null || b[this.sortBy] == '');
                                if( aNull && bNull ){
                                    return 0;
                                }else if( aNull ){
                                    return -1;
                                }else if( bNull ){
                                    return 1;
                                }else{
                                    let aStr = a[this.sortBy].toString();
                                    let bStr = b[this.sortBy].toString();
                                    let res = aStr.localeCompare(bStr);
                                    if(res == 0){
                                        return 0
                                    }else{
                                        return (res < 0) ? -1 : 1;
                                    }
                                }
                            };
                        }else if(this.sortHeader.type == 'boolean'){
                            this.sortFunction = (a,b)=>{
                                // Compare a < b
                                let aNull = (a[this.sortBy] == null || a[this.sortBy] == '');
                                let bNull = (b[this.sortBy] == null || b[this.sortBy] == '');
                                if( aNull && bNull ){
                                    return 0;
                                }else if( aNull ){
                                    return -1;
                                }else if( bNull ){
                                    return 1;
                                }else if(!b[this.sortBy]){
                                    // if b is false, and not null.
                                    // if a is not null, a can never be smaller than b
                                    return 1;
                                }else if(b[this.sortBy] == a[this.sortBy]){
                                    // if a == b, then a isnt smaller
                                    return 0;
                                }else{
                                    // b cannot be false, or null.
                                    // a is not null, and isnt the same as b
                                    // b is true, and a is false, therefore a < b is true
                                    return -1;
                                }
                            };
                        }else if(this.sortHeader.type == 'photo' || this.sortHeader.type == 'attachment'){
                            // Does it exist, then by value
                            this.sortFunction = (a,b)=>{
                                // Compare a < b
                                let aNull = (a[this.sortBy] == null || a[this.sortBy] == '');
                                let bNull = (b[this.sortBy] == null || b[this.sortBy] == '');
                                if( aNull && bNull ){
                                    return 0;
                                }else if( aNull ){
                                    return -1;
                                }else if( bNull ){
                                    return 1;
                                }else{
                                    if(a[this.sortBy] == b[this.sortBy]){
                                        return 0;
                                    }else{
                                        return (a[this.sortBy] < b[this.sortBy]) ? -1 : 1;
                                    }
                                }
                            };
                        }

                        this.items.sort((a,b)=>{
                            return this.sortFunction(a,b)
                        });
                        if(this.sortDesc){
                            this.items = this.items.reverse();
                        }
                        this.currentlySortedBy = this.sortBy;
                        this.setVisibleItems();
                        this.processingUpdate = false;
                    }
                }
            }else{
                this.setVisibleItems();
                this.processingUpdate = false;
            }
        },
        openPivotModal(){
            this.$refs['pivot-creation-modal'].show()
        },
        generatePivotTable(){
            var xOpts = _.uniq(_.pluck(this.items, this.pivotModal.horizField));
            var yOpts = _.uniq(_.pluck(this.items, this.pivotModal.vertField));
            
            var pivotObj = {};
            
            yOpts.forEach((y)=>{
                var tmp = {}
                xOpts.forEach((x)=>{
                    tmp[x] = 0;
                })
                pivotObj[y] = tmp;
            })

            // Now Read Through Once To Create The Pivot Values
            this.items.forEach((itm)=>{
                pivotObj[itm[this.pivotModal.vertField]][itm[this.pivotModal.horizField]]++;
            })

            var fields = [
                { key: ' ', stickyColumn: true, isRowHeader: true, variant: 'dark' },
            ]
            var items = [];
            
            // var header = { " ": '', }
            xOpts.forEach((x)=>{
                fields.push(x);
                // header[x] = x;
            })
            // items.push(header);

            yOpts.forEach((y)=>{
                var builtRow = { " ": y }
                var cur = pivotObj[y];
                xOpts.forEach((x)=>{
                    builtRow[x] = cur[x]
                })
                items.push(builtRow);
            })

            this.pivotModal.pivotTableFields = fields;
            this.pivotModal.resultPivot = items;
            this.$refs['pivot-table-display-modal'].show();
        },
        downloadPivotCSV(){
            var csvInput = [];
            // Get field order
            var fieldOrder = [];
            this.pivotModal.pivotTableFields.forEach((fld)=>{
                if(_.isString(fld)){
                    fieldOrder.push(fld);
                }else{
                    fieldOrder.push(fld.key);
                }
                
            })
            // Create Header Row
            var header = [];
            fieldOrder.forEach((fo)=>{
                header.push(fo);
            })
            csvInput.push(header);
            // Process Each Item in Items into a csv
            this.pivotModal.resultPivot.forEach((itm)=>{
                var row = [];
                fieldOrder.forEach((fo)=>{
                    row.push(itm[fo]);
                })
                csvInput.push(row);
            })
            // Stringify, Serve on Callback
            stringify(csvInput, (err, output)=>{
                if(err){
                    console.log(err);
                    this.makeToast('Failed To Generate CSV', 'Something went wrong durring the csv packing process.', 'danger')
                }else{
                    fileDownload(output, 'pivot.csv');
                }
            })
        },
        closePivotModal(){
            this.pivotModal = {
                pivotTableFields: [],
                resultPivot: [],
                vertField: null,
                horizField: null
            };
            this.$refs['pivot-creation-modal'].hide();
        },
        closePivotTableDisplayModal(){
            this.$refs['pivot-table-display-modal'].hide();
        },
        makeToast(toastTitle, toastBody = '', toastVariant = null, timer = 20){
            this.$bvToast.toast(toastBody, {
                variant: toastVariant,
                toaster: 'b-toaster-bottom-center',
                title: toastTitle,
                autoHideDelay: timer * 1000,
                appendToast: true
            })
        },
        downloadCSV(){
            this.fetch(()=>{
                var csvInput = [];
                // Get field order
                var fieldOrder = [];
                this.fields.forEach((fld)=>{
                    fieldOrder.push(fld.name);
                })
                // Create Header Row
                var header = [];
                fieldOrder.forEach((fo)=>{
                    header.push(fo);
                })
                csvInput.push(header);
                // Process Each Item in Items into a csv
                this.items.forEach((itm)=>{
                    var row = [];
                    fieldOrder.forEach((fo)=>{
                        row.push(itm[fo]);
                    })
                    csvInput.push(row);
                })
                // Stringify, Serve on Callback
                stringify(csvInput, (err, output)=>{
                    if(err){
                        console.log(err);
                        this.makeToast('Failed To Generate CSV', 'Something went wrong durring the csv packing process.', 'danger')
                    }else{
                        fileDownload(output, 'list.csv');
                    }
                })
            })
            
        },
        deleteFilter(index, elementIndex){
            this.filterElems[index].elms.splice(elementIndex, 1);
            this.filterChange();
        },
        deleteGroup(index){
            this.filterElems.splice(index, 1);
            this.filterChange();    
        },
        parentToggle(index) {
            this.tree[index].expanded = !this.tree[index].expanded
        },
        childToggle(parentIndex, childIndex){
            this.tree[parentIndex].children[childIndex].selected = !this.tree[parentIndex].children[childIndex].selected;
            this.changedSinceLastSave = true;
        },
        recursiveCheckIfFilterHasParent(filterElm, parent){
            if(filterElm.isGroup){
                // Its a group, check all children
                if(filterElm.children.length == 0){
                    return false;
                }else{
                    let res = false;
                    filterElm.children.forEach((child)=>{
                        res = res || this.recursiveCheckIfFilterHasParent(child, parent);
                    })
                    return res;
                }
            }else{
                return filterElm.parent == parent;
            }
        },
        recursiveRemoveFiltersWithParent(allFilters, parent){
            var removeIndexes = [];
            allFilters.forEach((filt, idx)=>{
                if(filt.isGroup){
                    filt.children = this.recursiveRemoveFiltersWithParent(filt.children, parent);
                }else if(filt.parent == parent){
                    removeIndexes.push(idx);
                }
            })
            removeIndexes.forEach((idx)=>{
                allFilters.splice(idx, 1);
            })

            return allFilters;
        },
        deselectAllChildren(parent){
            parent.children.forEach((child)=>{
                var curPos = this.fields.indexOf(child);
                if(curPos != -1){
                    child.selected = false;
                    this.fields.splice(curPos, 1);
                }
            })
        },
        addOrRemoveFromTableFields(child){
            this.changedSinceLastSave = true;
            //Check if removal is required
            var curPos = this.fields.indexOf(child);
            if(curPos != -1){
                var previousParents = _.uniq(_.pluck(this.fields, 'parent'));
                var postParents = _.uniq(_.pluck(this.fields.filter((fld)=>{ return fld != child; }), 'parent'));
                var diff = _.difference(previousParents, postParents);
                // console.log(diff);
                if(diff.length != 0){
                    var continueWithFilterCheck = (askFirst = true)=>{
                        // check if this alters a filter
                        if(this.filterElems.length > 0){
                            // It might alter a filter
                            var filtersReliantOnThisParent = this.filterElems.filter((flt)=>{
                                return this.recursiveCheckIfFilterHasParent(flt, diff[0]);
                            });
                            if(filtersReliantOnThisParent.length != 0){
                                if(askFirst){
                                    var result = confirm("Removing this field will remove one or more filters. Note that this may leave your filter with empty groups.");
                                }else{
                                    var result = true;
                                }
                                if(result == true){
                                    // The user is okay with removing those filters
                                    this.recursiveRemoveFiltersWithParent(this.filterElems, diff[0])
                                    this.fields.splice(curPos, 1);
                                }else{
                                    // The user doesnt want to remove those filters
                                    child.selected = true;
                                }
                            }else{
                                // There are no filters that rely on this parent
                                this.fields.splice(curPos, 1);
                            }
                        }else{
                            // There are no filters, we can remove parents whenever we want.
                            this.fields.splice(curPos, 1);
                        }
                    };
                    // Removing this item will remove this parent, 
                    // Check if this will make a current selection invalid
                    var okay = true;
                    var willRemove = [];
                    this.tree.forEach((parent)=>{
                        if(postParents.includes(parent.name)){
                            let par = this.parentAllowed(parent, postParents, false);
                            if(par == false){
                                willRemove.push(parent);
                            }
                            okay = okay && par;
                        }
                    })
                    if(okay == false){
                        var removeResult = confirm(`Removing this field will remove ${diff[0]} and the following other parents: ${willRemove.map((wr)=>{ return wr.name; }).join(', ')}. This will also remove any filters based on those parents. All fields under these parents will also be removed from the list.`)
                        if(removeResult == true){
                            // Remove those fields
                            willRemove.forEach((removeParent)=>{
                                this.deselectAllChildren(removeParent)
                                this.recursiveRemoveFiltersWithParent(this.filterElems, removeParent)
                            })
                            continueWithFilterCheck(false);
                        }else{
                            // The user decided not to deselect the child
                            child.selected = true;
                        }
                    }else{
                        continueWithFilterCheck();
                    }
                    
                }else{
                    // No available parent changes
                    this.fields.splice(curPos, 1);
                }
                
            }else{
                // Its not here
                if(child.type == "attachment" || child.type == "photo" || child.type == "link"){
                    child.special = true;
                }else{
                    child.special = false;
                }
                this.fields.push(child);
            }
        },
        filterOptionsAndBuildParentAndChildren(allOptions, parentName){
            var build = {
                name: parentName, 
                expanded: false, 
                children: [],
                showInfo: false,
                infoValue: null
            };
            var buildableFields = allOptions.filter((opt)=>{ return opt.ui_group == parentName});
            buildableFields.forEach(fld => {
                build.children.push({
                    name: fld.display_name, 
                    dataName: fld.data_name, 
                    selected: false, 
                    id: fld.id, 
                    type: fld.type, 
                    parent: fld.ui_group, 
                    parent_id: fld.parent_id, 
                    parent_table: fld.parent_table,
                    selections: []
                });
            });
            return build;
        },
        packList(){
            var pack = {
                visibility: {
                    filter: this.filterVis,
                    map: this.mapCollapseVis,
                    result: this.resultCollapseVis,
                    parents: []
                },
                map:{
                   selectedFeature: this.mapSelectedFeature,
                   colorBy: this.mapColorBy
                },
                sortBy: this.sortBy,
                sortDesc: this.sortDesc,
                filters: [],
                fields: []
            };
            
            this.tree.forEach((parent)=>{
                if(parent.expanded){
                    pack.visibility.parents.push(parent.name);
                }
            })

            pack.filters = this.parseFilterRecursively(this.filterElems);

            this.fields.forEach((header, idx)=>{
                var tmp = {
                    id: header.id,
                    position: idx,
                    selections: header.selections,
                    search: header.search || null
                };
                pack.fields.push(tmp);
            });
            // console.log(pack);
            // console.log(JSON.stringify(pack))
            return pack;
        },
        parseFilterRecursively(elms){
            var packed = [];
            elms.forEach((elm, idx)=>{
                var tmp;
                if(elm.isGroup == true || elm.children.length > 0){
                    
                    tmp = {
                        position: idx,
                        field: elm.field,
                        operator: elm.operator,
                        value: elm.value,
                        combination: elm.combination,
                        isGroup: elm.isGroup,
                        negate: elm.negate,
                        children: this.parseFilterRecursively(elm.children)
                    };
                    if(elms.length - 1 == idx){ tmp.combination = 'NULL';}
                    packed.push(tmp);
                }else{
                    tmp = {
                        position: idx,
                        field: elm.field,
                        operator: elm.operator,
                        value: elm.value,
                        combination: elm.combination,
                        isGroup: elm.isGroup,
                        children: []
                    };
                    if(elms.length - 1 == idx){ tmp.combination = 'NULL';}
                    packed.push(tmp);
                }
            })
            return packed;
        },
        fetch(callback = null){
            this.axiosController = new AbortController();
            this.loadingModalShowProgressBar = false;
            this.currentLoadingProcess = 0;
            this.loadingStatus = "Server Processing";
            this.$refs['loading-modal'].show();
            this.waiting = true;
            this.moreThanLimitItems = false;
            var packed = this.packList();
            this.items = [];
            butils.customInstance.infiniteTime()
            .post(process.env.VUE_APP_API_BASE_URL + "/list_builder/run.json?dehydrate", packed, {
                signal: this.axiosController.signal,
                onDownloadProgress: progressEvent => {
                    let percentCompleted = Math.floor(progressEvent.loaded / progressEvent.total * 100)
                    this.loadingStatus = `Downloading ${butils.formatters.requestSizeToHumanReadableSize(progressEvent.total)}`;
                    this.loadingModalShowProgressBar = true;
                    this.currentLoadingProcess = percentCompleted;
                }
            })
            .then((resp)=>{
                var dehydrated = resp.data.result;
                var hydrated = [];
                dehydrated.forEach((row)=>{
                    var curRehyd = {};
                    for (let index = 0; index < this.fields.length; index++) {
                        curRehyd[this.fields[index].name] = row[index];
                    }
                    hydrated.push(curRehyd);
                })
                this.$refs['loading-modal'].hide();
                this.rawItems = _.clone(hydrated);
                this.items = hydrated;
                this.currentlySortedBy = null;
                this.$bvToast.toast(`Completed Fetch Succesfully with ${hydrated.length} result rows`, {
                    variant: 'warning',
                    toaster: 'b-toaster-bottom-left',
                    title: `${hydrated.length} Results`,
                    autoHideDelay: 5 * 1000,
                    appendToast: true
                })
                // Calculate the Available Header Options
                this.calculateAvailableHeaderOptions();
                // Update Table Data
                this.localTableDataUpdate(callback);
                
                this.waiting = false;
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning')
                    this.waiting = false;
                }else if(butils.axiosErrorIsCancel(err)){
                    butils.createToast(this, 'Request Canceled', 'List Builder Request Canceled', 'info', 5);
                    this.waiting = false;
                }else{
                    console.log(err);
                    this.makeToast('Failed To Fetch List', 'Something went wrong durring the list fetch process.', 'danger');
                    this.waiting = false;
                }
            })
        },
        openFilterModalForAdd(){
            this.filterModal = {
                editingFilter: null,
                title: "Add Filter",
                okTitle: 'Add',
                selectedField: null,
                compareOperator: null,
                compareTo: null,
                operatorOptions: [],
                selectedFieldType: null
            };
            this.$refs['filter-modal'].show()
        },
        closeFilterModal(){
            this.filterModal = {
                editingFilter: null,
                title: null,
                okTitle: 'OK',
                selectedField: null,
                compareOperator: null,
                compareTo: null,
                operatorOptions: [],
                selectedFieldType: null
            };
            this.$refs['filter-modal'].hide();
        },
        openFilterModalForEdit(filter){
            this.filterModal = {
                editingFilter: filter,
                title: "Edit Filter",
                okTitle: 'Done',
                selectedField: _.clone(filter.field),
                compareOperator: _.clone(filter.operator),
                compareTo: _.clone(filter.value),
                operatorOptions: [],
                selectedFieldType: null
            };
            this.updateFilterAvailableCompareOptionsAndFieldType();
            this.$refs['filter-modal'].show()
        },
        doneFilterElement(){
            if(this.filterModal.editingFilter == null){
                this.addFilterElement();
            }else{
                this.editFilterElement();
            }
        },
        addFilterElement(){
            // TODO: Validate
            var field = this.rawAvailableFields.filter((avl)=>{ return avl.id == this.filterModal.selectedField })[0];
            var operator = this.filterOperatorsPerType[field.type].filter((fop)=>{ return fop.value == this.filterModal.compareOperator; })[0];
            var tmp = { 
                id: uuidv4(),
                fieldName: field.display_name,
                parent: field.ui_group,
                operatorName: operator.text,
                field: this.filterModal.selectedField,
                operator: this.filterModal.compareOperator,
                value: this.filterModal.compareTo,
                combination: 'AND',
                isGroup: false,
                children: []
            }
            this.filterElems.push(tmp);
            this.closeFilterModal();
            this.filterChange();
        },
        editFilterElement(){
            var operator = this.filterModal.operatorOptions.filter((fop)=>{ return fop.value == this.filterModal.compareOperator; })[0];            this.filterModal.editingFilter.id = uuidv4();
            this.filterModal.editingFilter.operatorName = operator.text;
            this.filterModal.editingFilter.field = this.filterModal.selectedField;
            this.filterModal.editingFilter.operator = this.filterModal.compareOperator;
            this.filterModal.editingFilter.value = this.filterModal.compareTo;
            this.closeFilterModal();
            this.filterChange();
        },
        loadFromJSON(json){
            // console.log(`Loading From JSON`)
            this.waiting = true;
            // console.log(json)
            // Clear Everything Out
            this.fields = [];
            this.items = [];
            this.filterElems = [];
            this.currentlySortedBy = null;
            this.sortBy = json.sortBy || null;
            this.sortDesc = json.sortDesc || false;
            this.tree.forEach((par)=>{
                    par.expanded = false;
                    // For Each Parent, Try To Find A Matching Child
                    par.children.forEach((child)=>{
                        child.selected = false;
                        child.search = null;
                        child.selection = [];
                    })
                })
            // Handle Each Field
            json.fields.forEach((inp)=>{
                var match = null;
                this.tree.forEach((par)=>{
                    // For Each Parent, Try To Find A Matching Child
                    par.children.forEach((child)=>{
                        if(child.id == inp.id){
                            match = child;
                            child.selected = true;
                            child.selections = inp.selections || [];
                            child.search = inp.search || null;
                        }
                    })
                })
                if(match != null){
                    this.addOrRemoveFromTableFields(match);
                }else{
                    // Did not find match for that id
                    console.log(`Could Not Find Match For Field ${inp.id} When Loading From Saved List`)
                }
            })

            if(_.has(json, 'visibility')){
                this.filterVis = json.visibility.filter;
                this.mapCollapseVis = json.visibility.map;
                this.resultCollapseVis = json.visibility.result;
                this.tree.forEach((parent)=>{
                    if(_.contains(json.visibility.parents, parent.name)){
                        parent.expanded = true;
                    }
                })
            }

            if(_.has(json, 'map')){
                this.mapSelectedFeature = json.map.selectedFeature;
                this.mapColorBy = json.map.colorBy;
            }

            this.filterElems = this.loadFilterRecursive(json.filters);
            this.waiting = false;
            this.changedSinceLastSave = false;
            // TODO: Conditional Logic to ensure that list pulled is sane
            if(this.fields.length > 0){
                this.fetch();
            }
            
        },
        loadFilterRecursive(filt){
            var res = [];
            filt.forEach((fl)=>{
                
                var tmp;
                if(fl.isGroup){
                    tmp = { 
                        id: uuidv4(),
                        fieldName: null,
                        parent: null,
                        operatorName: null,
                        field: null,
                        operator: null,
                        value: null,
                        combination: fl.combination,
                        isGroup: fl.isGroup,
                        negate: ( (_.has(fl,'negate')) ? fl.negate : false ),
                        children: this.loadFilterRecursive(fl.children)
                    }
                    res.push(tmp);
                }else{
                    var field = this.rawAvailableFields.filter((avl)=>{ return avl.id == fl.field })[0];
                    var operator = this.filterOperatorsPerType[field.type].filter((fop)=>{ return fop.value == fl.operator; })[0];
                    tmp = { 
                        id: uuidv4(),
                        fieldName: field.display_name,
                        parent: field.ui_group,
                        operatorName: operator.text,
                        field: fl.field,
                        operator: fl.operator,
                        value: fl.value,
                        combination: fl.combination,
                        isGroup: false,
                        children: []
                    };
                    res.push(tmp);
                }
            })
            return res;
        },
        loadSpecifiedList(listID){
            this.waiting = true;
            instance.get(process.env.VUE_APP_API_BASE_URL + `/list_builder/saved/${listID}.json`)
                .then((listResp)=>{
                    var packed = listResp.data.result;
                    this.loadedFromPackage = packed;
                    // Load Save Package With Info
                    this.savePackage.id = packed.id;
                    this.savePackage.name = packed.name;
                    this.savePackage.description = packed.description;
                    this.savePackage.disabled = packed.disabled;
                    if(packed.global_shared){
                        this.savePackage.id = null;
                        this.savePackage.permissions = 'user';
                    }else if(packed.account_shared){
                        if(packed.allow_account_editing){
                            this.savePackage.permissions = 'accountEdit';
                        }else{
                            this.savePackage.permissions = 'account';
                        }
                    }
                    this.loadFromJSON(packed.list);
                })
                .catch((err)=>{
                    console.log(err);
                    this.makeToast('Failed To Fetch Requested Saved List', 'Something went wrong when fetching the requested saved list.', 'danger');
                    this.waiting = false;
                })
        },
        loadSpecifiedStaticList(staticName){
            // This needs a cancel token
            this.waiting = true;
            instance.get(process.env.VUE_APP_API_BASE_URL + `/list_builder/static/${staticName}`)
                .then((listResp)=>{
                    var packed = listResp.data.result;
                    this.loadedFromPackage = packed;
                    // Load Save Package With Info
                    this.savePackage.name = packed.name;
                    this.savePackage.description = packed.description;
                    this.savePackage.disabled = false;
                    this.savePackage.id = null;

                    this.loadFromJSON(packed.list);
                })
                .catch((err)=>{
                    console.log(err);
                    this.makeToast('Failed To Fetch Requested Static List', 'Something went wrong when fetching the requested static list.', 'danger');
                    this.waiting = false;
                })
        },
        loadSelectedList(){
            this.$router.replace({ path: `/home/listbuilder/${this.loadIDSelected}` })
            this.loadSpecifiedList(this.loadIDSelected);
        },
        saveListCopy(){
            this.savePackage.id = null;
            this.createOrUpdateSavedList();
        },
        createOrUpdateSavedList(callback = null){
            this.waiting = true;
            var list = this.packList();
            var saveMe = {
                id: this.savePackage.id,
                name: this.savePackage.name,
                description: this.savePackage.description,
                disabled: this.savePackage.disabled,
                account_shared: false,
                allow_account_editing: false,
                list: list,
                justSaved: true,
            };
            if(this.savePackage.permissions == 'user'){
                // Dont Change Nothing
            }else if(this.savePackage.permissions == 'account'){
                saveMe.account_shared = true;
            }else if(this.savePackage.permissions == 'accountEdit'){
                saveMe.account_shared = true;
                saveMe.allow_account_editing = true;
            }

            instance.post(process.env.VUE_APP_API_BASE_URL + "/list_builder/saved/list.json", saveMe)
            .then((resp)=>{
                this.makeToast('Save Successful', 'New List Saved Successfully', 'success', 5);
                console.log(resp.data.result[0].upsert_saved_list);
                this.loadIDSelected = resp.data.result[0].upsert_saved_list;
                this.savePackage.id = resp.data.result[0].upsert_saved_list;
                this.loadedFromPackage = saveMe;
                this.waiting = false;
                this.changedSinceLastSave = false;
                if(callback != null){
                    callback(true);
                }
            })
            .catch((err)=>{
                console.log(err);
                this.makeToast('Failed to save or update list', 'Something went wrong when updating or saving this list.', 'danger');
                this.waiting = false;
                if(callback != null){
                    callback(false);
                }
            })
        },
        getAvailableLists(){
            this.refreshingSavedLists = true;
            this.waiting = true;
            instance.get(process.env.VUE_APP_API_BASE_URL + "/list_builder/saved/list.json")
            .then((resp)=>{
                var lists = resp.data.result;
                this.loadableLists = lists;
                this.loadOptions = [];
                this.loadableLists.forEach((lst)=>{
                    this.loadOptions.push({ value: lst.id, text: lst.name});
                })
                this.waiting = false;
                this.refreshingSavedLists = false;
            })
            .catch((err)=>{
                if(_.has(err, "message") && err.message == "canceled"){
                    console.log("List Builder Request Canceled")
                }else{
                    console.log(err);
                    if(butils.isError401(err)){
                        butils.createToast(this, 'Logged Out', 'Login Again', 'warning')
                    }else{
                        this.makeToast('Failed To Fetch Saved Lists', 'Something went wrong when fetching your list of saved lists.', 'danger');
                    }
                    
                    this.waiting = false;
                    this.refreshingSavedLists = false;
                }
            })
        },
        addFilterGroup(){
             var tmp = { 
                combination: 'NULL',
                isGroup: true,
                children: [],
                negate: false
            }
            this.filterElems.push(tmp);
        },
        setThumbSize(size){
            this.photoThumbnailSize = size;
        },
        jumpToLetterBuilder(){
            // Check if list is saved, or has been changed since last save
            var list = this.packList();
            var saveMe = {
                id: this.savePackage.id,
                name: this.savePackage.name,
                description: this.savePackage.description,
                disabled: this.savePackage.disabled,
                account_shared: false,
                allow_account_editing: false,
                list: list
            };
            if(this.savePackage.permissions == 'user'){
                // Dont Change Nothing
            }else if(this.savePackage.permissions == 'account'){
                saveMe.account_shared = true;
            }else if(this.savePackage.permissions == 'accountEdit'){
                saveMe.account_shared = true;
                saveMe.allow_account_editing = true;
            }
            if(_.isEqual(saveMe, this.loadedFromPackage)){
                // Push to Issue To Fulcrum
                this.$router.push({path: '/home/letterbuilder/unknown/' + this.savePackage.id });
            }else{
                // Warn user that changes need to be saved, offer to save all changes now
                this.warnNotSaved(this.pushLetter);
            }
        },
        pushLetter(){
            this.$router.push({path: '/home/letterbuilder/unknown/' + this.savePackage.id });
        },
        jumpToEmailBuilder(){
            // Check if list is saved, or has been changed since last save
            var list = this.packList();
            var saveMe = {
                id: this.savePackage.id,
                name: this.savePackage.name,
                description: this.savePackage.description,
                disabled: this.savePackage.disabled,
                account_shared: false,
                allow_account_editing: false,
                list: list
            };
            if(this.savePackage.permissions == 'user'){
                // Dont Change Nothing
            }else if(this.savePackage.permissions == 'account'){
                saveMe.account_shared = true;
            }else if(this.savePackage.permissions == 'accountEdit'){
                saveMe.account_shared = true;
                saveMe.allow_account_editing = true;
            }
            if(_.isEqual(saveMe, this.loadedFromPackage)){
                // Push to Issue To Fulcrum
                this.$router.push({path: '/home/emailbuilder/unknown/' + this.savePackage.id });
            }else{
                // Warn user that changes need to be saved, offer to save all changes now
                this.warnNotSaved(this.pushEmail);
            }
        },
        pushEmail(){
            this.$router.push({path: '/home/emailbuilder/unknown/' + this.savePackage.id });
        }
    },
    watch:{
        mapCollapseVis: function (newMapVis, previousMapVis){
            if(newMapVis == true){
                setTimeout(()=>{
                    this.mapUpdatePageSize();
                }, 500)
            }
        },
        sidebarOpen: function (newSideBar, previousSideBar){
            setTimeout(()=>{
                this.mapUpdatePageSize();
            }, 500)
        }
    },
    computed: {
        availableForColoring: function(){
            var availableOptions = [
                {text: 'Nothing Available', value: null}
            ];
            _.keys(this.headerOptions).forEach((opt)=>{
                if(this.headerOptions[opt].length > 0){
                    // We can add this
                    availableOptions.push({text: opt, value: opt});
                }
            })
            return availableOptions;
        },
        availableMappableFeatures: function(){
            var availableOptions = [];
            if(this.fields != null && this.fields.length > 0){
                var options = [
                    {text: 'Device GPS Coordinates', value: {name: 'Device GPS Coordinates', lat: 'Device Latitude', lng: 'Device Longitude'}},
                    {text: 'Connection/Meter GPS Coordinates', value: {name: 'Connection/Meter GPS Coordinates', lat: 'Connection Latitude', lng: 'Connection Longitude'}},
                    {text: 'Survey GPS Coordinates', value: {name: 'Survey GPS Coordinates',lat: 'Survey Latitude', lng: 'Survey Longitude'}}
                ];
                
                options.forEach((opt)=>{
                    if(_.find(this.fields,(fld)=>{ return fld.name == opt.value.lat; }) != undefined && _.find(this.fields,(fld)=>{ return fld.name == opt.value.lng; }) != undefined ){
                        availableOptions.push(opt);
                    }
                })
            }
            return availableOptions;
            
        },
        availablePivotFields: function (){
            var options = [];
            this.fields.forEach((header)=>{
                var tmp = { value: header.name, text: `${header.name} [${header.type}] (${header.parent})`, type: header.type, parent: header.parent}
                options.push(tmp);
            })
            return options;
        },
        availableFilterFields: function () {
            var parentsForSelected = _.uniq(_.pluck(this.fields, 'parent'));
            var options = [];

            this.tree.forEach((par)=>{
                if(parentsForSelected.indexOf(par.name) != -1){
                    // We add this parent's children
                    par.children.forEach((cld)=>{
                        var tmp = { value: cld.id, text: `${cld.name} [${cld.type}] (${par.name})`, type: cld.type}
                        options.push(tmp);
                    })
                }
            })
            // Remove items which we dont have comparison operators for
            return options.filter((opt)=>{ return opt.type == 'text' || opt.type == 'numeric' || opt.type == 'timestamp with time zone' || opt.type == 'boolean' || opt.type == 'text[]' || opt.type == 'integer'});
        }
    },
    mounted(){
        var mapDefaults = this.$store.getters.getMapDefaults;
        if(mapDefaults != null){
            this.zoom = mapDefaults.zoom;
            this.center = [ mapDefaults.latitude, mapDefaults.longitude ];
        }
        this.axiosController = new AbortController();
        this.mapID = uuidv4();
        this.tiles = {
            url: "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJpY2hhcmRzMTQwIiwiYSI6ImNrYnllYzlicTBiY2IyeHQ2NGZreDU2ZjQifQ.PVvaJNTzI9zQReH1CYsteQ",
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
        }
        this.setupLeafletMap();
        this.waiting = true;
        this.tree = [];
        instance.get(process.env.VUE_APP_API_BASE_URL + "/list_builder/fields.json")
        .then((resp)=>{
            var allOptions = resp.data.result;
            this.rawAvailableFields = allOptions;
            var groups = _.pluck(allOptions, "ui_group");
            groups = _.uniq(groups);
            var child = {name: "Serial Number", selected: false};
            
            this.tree.push(this.filterOptionsAndBuildParentAndChildren(allOptions,"Customer"));
            groups.splice(groups.indexOf("Customer"),1);
            this.tree.push(this.filterOptionsAndBuildParentAndChildren(allOptions,"Site"));
            groups.splice(groups.indexOf("Site"),1);
            this.tree.push(this.filterOptionsAndBuildParentAndChildren(allOptions,"Connection"));
            groups.splice(groups.indexOf("Connection"),1);
            this.tree.push(this.filterOptionsAndBuildParentAndChildren(allOptions,"Device"));
            groups.splice(groups.indexOf("Device"),1);
            
            groups.forEach(grp => {
                this.tree.push(this.filterOptionsAndBuildParentAndChildren(allOptions, grp));
            });
            if(this.listID != null){
                if(butils.validate.isUUID(this.listID)){
                    // Fetch the list requested and load that into the system
                    this.loadSpecifiedList(this.listID);
                }else{
                    this.loadSpecifiedStaticList(this.listID);
                }
            }else{
                this.waiting = false;
            }
        })
        .catch((err)=>{
            if(_.has(err, "message") && err.message == "canceled"){
                console.log("List Builder Request Canceled")
            }else{
                console.log(err);
                this.makeToast('Failed To Fetch Available Fields', 'Something went wrong when fetching the available fields from the server.', 'danger');
                this.waiting = false;
            }
        })

        this.getAvailableLists();
    },
    created(){
        this.debouncedLocalUpdate = _.debounce(()=>{ this.localTableDataUpdate(); console.log("Debounced Update Called"); this.changedSinceLastSave = true;}, 1000);
    },
    beforeDestroy(){
        console.log("List Builder Running Before Destroy Lifecycle Hooks")
        if(this.axiosController != null){
            this.axiosController.abort();
        }
        
        if(this.leafGeoHandle != null){
            this.leafGeoHandle.remove();
            this.map.removeLayer(this.leafGeoHandle.layer);
            L.glify.pointsInstances.splice(0, 1);
            this.leafGeoHandle = null;
            //this.leafGeoHandle.removeFrom(this.map);
        }
        this.axiosController = null;
        this.map.remove();
        this.map = null;
        this.items = null;
        this.rawItems = null;
        this.tiles = null;
        this.refreshingSavedLists = null;
        this.sidebarOpen = null;
        this.leafGeoHandle = null;
        this.geoJSON = null;
        this.geoJSONReady = null;
        this.tileLayer = null;
        this.tilerReady = null;
        this.mapColorBy = null;
        this.mapCollapseVis = null;
        this.resultCollapseVis = null;
        this.mapSelectedFeature = null;
        this.mapShowingDots = null;
        this.mapSkippedForMissingGPS = null;
        this.mapID = null;
        this.zoom = null;
        this.center = null;
        this.markers = null;
        this.somethingSelected = null;
        this.selected = null;
        this.tileProviders = null;
        this.accessToken = null;
        this.keyLegendControl = null;
        this.photoThumbnailSize = null;
        this.photoThumbnailSizeOptions = null;
        this.updatingNestedFilter = null;
        this.resultsVis = null;
        this.waiting = null;
        this.processingUpdate = null;
        this.loadedFromPackage = null;
        this.savePackage = null;
        this.loadableLists = null;
        this.loadIDSelected = null;
        this.loadOptions = null;
        this.tree = null;
        this.rawAvailableFields = null;
        this.fields = null;
        this.headerOptions = null;
        this.setLimit = null;
        this.moreThanLimitItems = null;
        this.limitedItemsForDisplay = null;
        this.dragging = null;
        this.filterVis = null;
        this.testVis = null;
        this.filterElems = null;
        this.filterModal = null;
        this.pivotModal = null;
        this.filterOperatorsPerTypes = null;
        this.booleanCompareToOptions = null;
        this.combinationOptions = null;
        this.sortBy = null;
        this.sortHeader = null;
        this.sortDesc = null;
        this.sortFunction = null;
        this.currentlySortedBy = null;
        this.baseFileURL = null;
        this.downloadToDoList = null;
        this.resultHeight = null;
        this.changedSinceLastSave = null;
    },
    beforeMount (){
        //console.log(this.value)
    },
    updated(){
        // this.$nextTick(function () {
        //     this.mapUpdatePageSize();
        // })
        
    }
}
</script>
<style scoped>
.overlay-top{
    z-index: 200;
}
#collapse-results{
    overflow-y: scroll;
    overflow-x: scroll;
    flex: 1;
}
#lb-table{
    overflow-y: scroll;
    overflow-x: scroll;
    max-height: calc(100vh - 56px);
}
.scrollable-veritically{
    height: calc(100vh - 152px);
    overflow-y: auto;
}
#fit-window{
    height: calc(100vh - 56px);
    width: calc(100vw - 16px);
    display: flex;
    flex-flow: row;
    /* overflow-y: scroll;
    overflow-x: scroll; */
    /* margin-left: -15px; */
    /* margin-right: -15px; */
}
#table-holder{
    display: flex;
    flex-flow: column;
    max-height: calc(100vh - 56px);
    transition: all 0.3s;
    /* white-space: nowrap; */
}
#table-holder.lb-th-sb-open{
    width: calc(100% - 315px);
}
#table-holder.lb-th-sb-closed{
    width: 100%;
}
.lb-table-th{
    white-space: nowrap;
}
.lb-table-td{
    overflow:visible;
    white-space: nowrap;
}
#lb-sidebar{
    background-color: hsl(0, 0%, 80%);
    position: fixed;
    top: 56px;
    min-height: calc(100vh - 56px);
    z-index: 1003;
    transition: all 0.3s;
}
.lb-sb-nav-open{
    width: 300px;
    left: 0;
}
.lb-sb-nav-closed{
    width: 2px;
    left: -300px;
}
#lb-sidebar-container{
    display: inline-block;
    position:sticky;
    top: 56px;
    /* margin-left: -15px; */
    min-height: calc(100vh - 56px);
    line-height: calc(100vh - 56px);
    transition: all 0.3s;
}
div.lb-sb-cont-closed{
    width: 2px;
}
div.lb-sb-cont-open{
    min-width: 300px;
}
.collapse-control-btn{
    text-align: right;
}
.xpander-icon{
    background-color: grey;
    padding-left: 1px;
    padding-top: 30px;
    padding-right: 5px;
    padding-bottom: 30px;
    color: white;
    border-radius: 0px 30px 30px 0px;

}
.xpander-icon-expanded{
    padding-left: 10px;
    padding-top: 30px;
    padding-right: 5px;
    padding-bottom: 30px;
}
.xpander-icon:hover{
    cursor:pointer;
    background-color: darkgrey;
}
.centered-expand-control{
    position: absolute;
    top:50%;
    z-index: 999;
    height: 84px;
    transition: all 0.3s;
}
.xpand-open{
    -ms-transform: translateX(300px);
    transform: translateX(300px)
}
.xpand-close{
    -ms-transform: translateX(0px);
    transform: translateX(0px)
}
#field-list{
    width: 15vw;
    overflow-y: scroll;
}

#mapContainer{
    position: relative;
    width: 100%;
    min-height: 20vh;
    height: calc(100vh - 179px);
    resize: vertical;
}
.even-odd-checkbox-rows:nth-child(even) {
    background-color: #cccccc
}
.even-odd-checkbox-rows:nth-child(odd) {
    background: #999999
    }
.header-available-options-list{
    overflow-y: scroll;
    max-height: 15vh;
}
.header-dropdown{
    min-width: 15vw;
}
th.green-th{
    color: green;
}


.hoverable:hover{
    background-color: lightgreen;
}
.hoverable{
    cursor:pointer;
}
.clickable{
    cursor: pointer;
}
.movable{
    cursor: move;
}
.expanded-child {
  border-left: 4px dotted black;
}
.scrollable{
    overflow-x: scroll;
    white-space: nowrap;
}
.tableheadernowrapping{
    white-space: nowrap;
}
th.delimited{
    border-left: 1px;
    border-left-style: solid;
    border-left-color: white;
}
div.drag-indicator{
    color: white;
    position: relative;
    top: 0;
    left: 0;
    /* border-left-color: lightgray;
    border-right-color:lightgray;
    border-left-width: 2px;
    border-right-width: 2px;
    border-left-style: solid;
    border-right-style: solid; */
}
.header-inline{
    display: inline-block;
}
.scroll-over{
    height: 100%;
    overflow-y: scroll;
}
.filter-combinator-select{
    width: 10vw;
}
.text-match-drop-down{
    font-size: 1.25em;
    line-height: 2em;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
    display: none;
}
.child-form-head{
    width: 100%;
}
.more-than-row{
    background-color: yellow;
}
div.act-like-button{
    background-color: black;
}
div.act-like-button:hover{
    background-color: darkgray;
    color: gold;
}
.sticky-header{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 1;
}

</style>
<style>
.popout-wrap-overflow{
    overflow-wrap: break-word;
    white-space: pre-wrap;
    min-width: 500px;
    max-width: 780px;
}
.map-legend{
    overflow-y: auto;
    background-color: rgba(255, 255, 255, 0.7);
    padding-top: 8px;
    padding-bottom: 4px;
}
.map-legend-single{
    height: 16px;
    margin-bottom: 4px;
}
.map-legend-dot{
    border-radius: 8px;
    width: 16px;
    height: 16px;
    display:inline-block;
    margin-right: 8px;
    margin-left: 8px;
}
.map-legend-title{
    display:inline-block;
    margin-right: 8px;
}
</style>

